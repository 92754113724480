import React from "react";
import Form from "./Form";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';

const Intro = ({ subscription_plan_id, subscription, terms_conditions, subscription_agreement,enterprise }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const data = useSelector((state) => state.fetchCheckout.content);
  const { content, plan } = data;
  let action=searchParams.get("action")
  return (
    <>
      <div className="colL">
        <div className="inner">
          <h4>{plan.plan_name}</h4>
          {
            !enterprise && !action ?
            <p>
            {plan.tagline}
            <br />
            Start your free {plan.trial_days} days trial
          </p>
          :
          ""
          }
          
          {
            subscription_plan_id === plan?.id ?
              <div className="alert alert-success"><strong>Subscribed!</strong> This is your current plan.</div>
              :
              <Form content={content} plan={plan} subscription={subscription} terms_conditions={terms_conditions} subscription_agreement={subscription_agreement} enterprise={enterprise} />
          }
        </div>
      </div>
    </>
  );
};

export default Intro;
