import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
    FETCH_FEEDBACK_CONTENT,
    FETCH_FEEDBACK_CONTENT_SUCCESS,
    FETCH_FEEDBACK_CONTENT_FAILED,
    FEEDBACK_MESSAGE,
    FEEDBACK_MESSAGE_SUCCESS,
    FEEDBACK_MESSAGE_FAILED,
} from "./actionTypes";

export const fetchFeedback = (formData) => (dispatch) => {
    dispatch({
        type: FETCH_FEEDBACK_CONTENT,
        payload: null
    });
    http
        .get(
            "feedback-page")
        .then(({ data }) => {
            console.log(data)
            dispatch({
                type: FETCH_FEEDBACK_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            localStorage.clear();
      window.location.reload();
            dispatch({
                type: FETCH_FEEDBACK_CONTENT_FAILED,
                payload: error
            });
        });
};

export const postFeedback = (formData) => (dispatch) => {
    dispatch({
        type: FEEDBACK_MESSAGE,
        payload: null
    });
    http
        .post("save-feedback", helpers.doObjToFormData(formData))
        .then(({ data }) => {
            if (data.status) {
                toast.success(
                    `${data.msg}`,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: FEEDBACK_MESSAGE_SUCCESS,
                    payload: data
                });
            } else {
                toast.error(
                    <Text string={data.msg} parse={true} />,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: FEEDBACK_MESSAGE_FAILED,
                    payload: null
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: FEEDBACK_MESSAGE_FAILED,
                payload: error
            });
        });
};