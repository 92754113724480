import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";

import {
  FETCH_CHECKOUT_CONTENT,
  FETCH_CHECKOUT_CONTENT_SUCCESS,
  FETCH_CHECKOUT_CONTENT_FAILED,
  SAVE_SUBSCRIPTION_PAYMENT,
  SAVE_SUBSCRIPTION_PAYMENT_SUCCESS,
  SAVE_SUBSCRIPTION_PAYMENT_FAILED
} from "./actionTypes";

export const fetchCheckout = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_CHECKOUT_CONTENT,
    payload: null
  });
  http
    .post("checkout-page", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data?.status === 0 && data?.no_member === 1) {
        window.location.href = "/login";
      } else if (data?.status === 0) {
        window.location.href = "/pricing";
      }
      dispatch({
        type: FETCH_CHECKOUT_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      localStorage.clear();
      window.location.reload();
      dispatch({
        type: FETCH_CHECKOUT_CONTENT_FAILED,
        payload: error
      });
    });
};

export const makeSubsPayment = (formData) => (dispatch) => {
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: SAVE_SUBSCRIPTION_PAYMENT,
      payload: null
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("make-subscription-payment", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        console.log(data);
        if (data.status) {
          toast.success(
            "You have successfully subscribed the plan.",
            TOAST_SETTINGS
          );
          dispatch({
            type: SAVE_SUBSCRIPTION_PAYMENT_SUCCESS,
            payload: data
          });
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 2000);
        } else {
          dispatch({
            type: SAVE_SUBSCRIPTION_PAYMENT_FAILED,
            payload: null
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_SUBSCRIPTION_PAYMENT_FAILED,
          payload: error
        });
        // const { data } = error.response;
        // localStorage.removeItem("authToken");
        // window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
