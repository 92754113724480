import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BiCircle } from 'react-icons/bi';
import { PiPolygon } from 'react-icons/pi';
import { FaTrashAlt } from 'react-icons/fa';  // Trash icon for delete
import { union, helpers } from "@turf/turf";
import Select, { components as SelectComponents } from 'react-select';
import wellknown from 'wellknown';
import { arraysEqual } from '../../../../helpers/helpers';
import CircleMode from './Circle.DrawControl';

import { useLocation, useNavigate } from 'react-router-dom';

import { DrawControlWrapper, customSelectStyles } from './DrawControl.style';

// Placed outside the scope of the Select component. This is so it won't re render every time select changes
const IconOption = (props) => (
  <SelectComponents.Option {...props}>
    <span>{props.data.label}</span>
    {props.data.icon}
  </SelectComponents.Option>
);

// Drawing modes including Delete option in the dropdown
const ALL_MODES = [
  { value: 'polygon', label: "Polygon", mode: 'draw_polygon', icon: <PiPolygon size={18} /> },
  { value: 'circle', label: "Circle", mode: 'draw_circle', icon: <BiCircle size={18} /> },
  { value: 'delete', label: "Delete", icon: <FaTrashAlt size={18} /> }  // Delete option
];



function DrawControl({ map, controls = [], setDrawnFeatures, propertyTypeExists, filteredProperties, setIsDrawToolsActive, setPntGeoJson, setStaticFeaute, setHoveredFeature, setShowWMS, setShowowners, setListingData, Maptoggle, setSearchQueryValues, setFilteredProperties, handleSubmit }) {
  const [selectedControl, setSelectedControl] = useState(null);
  const [availableControls, setAvailableControls] = useState([]);
  const drawRef = useRef(null);
  const [drawnFeatures, setDrawnFeaturesState] = useState([]);
  const [messageShown, setMessageShown] = useState(false);

  const resetFilteredProperties = () => setFilteredProperties([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [initialFilterState, setInitialFilterState] = useState(null);



  useEffect(() => {
    // Check if there is a property type filter applied
    const hasPropertyTypeFilter = filteredProperties?.some((property) =>
      property.type === "property_type"
    );

    // Only set the initial filter if it's not yet set, a filter is applied, and it's a property type filter
    if (!initialFilterState && hasPropertyTypeFilter) {
      setInitialFilterState(filteredProperties); // Store the initial filtered properties
    }
  }, [filteredProperties, initialFilterState]);

  // Update geometry and sync with state
  const updateGeometry = useCallback(
    (event) => {
      if (drawRef.current) {
        const feature = event.features[0];
        try {
          const wktGeom = wellknown.stringify(feature.geometry);
          // console.log("WKT Geometry:", wktGeom);

          const urlParams = new URLSearchParams(window.location.search);
          const geomUrl = urlParams.get('geometry');

          let combinedGeometry = feature.geometry;
          let geomUrlFeature;
          try {
            geomUrlFeature = wellknown.parse(geomUrl);
          } catch (e) { }

          if (geomUrlFeature) {
            combinedGeometry = union(feature.geometry, geomUrlFeature);
          }
          // feature.geometry = combinedGeometry;

          setDrawnFeaturesState(combinedGeometry);
          setDrawnFeatures(wellknown.stringify(combinedGeometry));
          Maptoggle();
          setSelectedControl(null); // Deselect tool after drawing
        } catch (error) {
          console.error("Error processing geometry:", error);
        }
      }
    },
    [setDrawnFeatures]
  );

  const onSelectControl = (option) => {
    if (!option) {
      setIsDrawToolsActive(false);
      setSelectedControl(null);
      map.getCanvas().style.cursor = ''; // Reset cursor
      return;
    }

    if (option.value === 'delete') {
      deleteDrawnFeatures(); // Call delete function if "Delete" is selected
      return;
    }

    setIsDrawToolsActive(true);
    setSelectedControl(option.value);
    map.getCanvas().style.cursor = 'crosshair'; // Set correct draw cursor

    if (drawRef.current) {
      drawRef.current.changeMode(option.mode);
    }
  };

  const deleteDrawnFeatures = (e) => {
    if (drawRef.current) {
      drawRef.current.deleteAll();
      setDrawnFeaturesState([]);
      setDrawnFeatures(null);
      // delete currentFilters from localStorage
      localStorage.removeItem('currentFilters');
      // localStorage.Item('listingData', null);
      // setPntGeoJson({ type: 'FeatureCollection', features: [] });
      setStaticFeaute(null); // this deletes the drawn feature
      localStorage.removeItem('drawnFeatures');
      // setHoveredFeature({ type: 'FeatureCollection', features: [] });
      setShowWMS(true); // this brings back all the points after deletion of drawn features
      // resetFilteredProperties();
      // Revert to initial state based on initialFilterState
      if (propertyTypeExists) {
        setFilteredProperties(initialFilterState);
        handleSubmit(new Event('submit'), "property_type");
      } else {
        resetFilteredProperties();
      }

      // console.log('resetFilteredProperties',resetFilteredProperties)
      // setListingData(null);
      Maptoggle();
      setIsDrawToolsActive(true);
      setSearchQueryValues(prevValues => ({
        ...prevValues,
        geometry: null,
      }));



      const urlParams = new URLSearchParams(location.search);
      urlParams.delete('geometry');

      navigate({
        pathname: '/search-property',
        search: urlParams.toString(),
      }, { replace: true });
      window.location.reload();
    }

  };

  useEffect(() => {

    if (drawnFeatures.length > 0) {
      const wktFeatures = drawnFeatures.map((feature) =>
        wellknown.stringify(feature.geometry)
      );
      localStorage.setItem('drawnFeatures', JSON.stringify(wktFeatures));
    }
  }, [drawnFeatures]);

  useEffect(() => {
    if (!map) return;

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
        draw_circle: CircleMode
      }
    });

    map.addControl(drawRef.current);
    map.on('draw.create', updateGeometry);
    map.on('draw.update', updateGeometry);

    map.getCanvas().style.cursor = 'grab'; // Ensure map cursor behavior is set initially

    return () => {
      map.off('draw.create', updateGeometry);
      map.off('draw.update', updateGeometry);
    };
  }, [map, updateGeometry]);

  useEffect(() => {
    const syncFeatures = () => {
      const allFeatures = drawRef.current.getAll().features;
      setDrawnFeaturesState(allFeatures);
      localStorage.setItem('drawnFeatures', JSON.stringify(allFeatures));
    };

    map.on('draw.create', syncFeatures);
    map.on('draw.update', syncFeatures);

    return () => {
      map.off('draw.create', syncFeatures);
      map.off('draw.update', syncFeatures);
    };
  }, [map]);

  useEffect(() => {
    let newControls = [...controls];
    if (!arraysEqual(newControls, availableControls)) {
      setAvailableControls(newControls);
    }
  }, [availableControls, controls]);

  useEffect(() => {
    if (!messageShown) {
      setMessageShown(true);
    }
    const availableControlOptions = ALL_MODES.filter((m) =>
      availableControls.includes(m.value)
    );
    setSelectedControl(
      availableControlOptions.find((m) => m.value === selectedControl) || null);
  }, [messageShown]);




  return (
    <DrawControlWrapper style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {!messageShown && (
        <div className="notification">
          Drawing a new feature will overwrite the existing one.
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Select
          placeholder="Draw"
          isClearable
          value={selectedControl}
          onChange={onSelectControl}
          options={ALL_MODES}  // Ensuring the full set of options is available
          components={{ Option: IconOption }}
          styles={customSelectStyles}
        />
      </div>
    </DrawControlWrapper>
  );
}

export default DrawControl;
