import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import http from "../../../helpers/http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { doObjToFormData } from "../../../helpers/helpers";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import { updateMEmberCredits } from "../../../states/actions/fetchSiteSettings";
import { useDispatch, useSelector } from "react-redux";

import { search } from "../../../states/actions/fetchnewsletteremail";

import DeleteLabel from "./deleteLabel";

const DetailFilters = (props) => {
  const {
    ToggleHideSearch,
    hideSearch,
    handleSubmitLabel,
    propertyData,
    userAvailableCredits,
  } = props;
  const searchQueryValues = useSelector(
    (state) => state.searchQuery.searchQueryValues
  );

  const [sort, setSort] = useState(false);
  const navigate = useNavigate();
  const [label, setLabel] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [labelColor, setLabelColor] = useState("");
  const [userdetailLabels, setUserdetailLabels] = useState([]);
  const [showNewLabelInput, setShowNewLabelInput] = useState(false);
  const [labels, setLabels] = useState([]);
  const { propertyId } = useParams();
  const [isPrintiing, setIsPrinting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [editLabel, setEditLAbel] = useState({ show: false, item: null });
  const handleChangeEditLabel = (e, item) => {
    if (e.target.name === "label_name") {
      setLabelName(e.target.value);
    }
    if (e.target.name === "label_color") {
      setLabelColor(e.target.value);
    }
  };
  const handleCloseEditLabel = (e) => {
    e.preventDefault();
    setLabelName("");
    setLabelColor("");
    setEditLAbel({ show: false, item: null });
  };
  const handleShowEditLabel = (e, item) => {
    console.log(item);
    e.preventDefault();
    setLabelName(item?.label_name);
    setLabelColor(item?.label_color);
    setEditLAbel({ show: true, item: item });
  };
  const sortValue = props?.sortValue;
  const setSortValue = props?.setSortValue;
  const sortByDrop = useRef();
  const selectSortValue = (e, value) => {
    e.preventDefault();
    setSortValue(value);
    setLabel(false);
    setSort(!sort);
    // props.handleSearchSortSubmit(e, value)
  };
  const ToggleSort = () => {
    setLabel(false);
    setSort(!sort);
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        !labelDrop?.current?.contains(e.target) &&
        !sortByDrop?.current?.contains(e.target)
      ) {
        setLabel(false);
        setSort(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);
  const showLabelInput = () => {
    setShowNewLabelInput(true);
  };
  const [previousPage, setPreviousPage] = useState("");
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const encodedPreviousUrl = queryParams.get("previousUrl");
    if (encodedPreviousUrl) {
      const decodedPreviousUrl = decodeURIComponent(encodedPreviousUrl);
      setPreviousPage(decodedPreviousUrl);
    }
  }, [location.search]);
  const goBack = (e) => {
    e.preventDefault();

    if ({ searchQueryValues }) {
      // console.log(searchQueryValues.previousUrl);return;
      // navigate(`/search-property?${searchQueryValues.previousUrl}`);
      window.location.href = `${searchQueryValues.previousUrl}`;
      // get drawn feature geometry from previousUrl

      localStorage.setItem(
        "drawnFeatures",
        JSON.stringify(searchQueryValues.previousUrl.geometry)
      );
    } else {
      // Fallback in case searchQueryValues is unavailable
      navigate("/search-property");
    }
  };

  const ToggleLabel = () => {
    setLabel(!label);
  };

  const handleLabelNameChange = (e) => {
    setLabelName(e.target.value);
  };

  const handleLabelColorChange = (e) => {
    setLabelColor(e.target.value);
  };
  const updateLabel = (e, label_id) => {
    // Perform API post request with labelName and labelColor
    // ...
    console.log(label_id, editLabel);
    e.preventDefault();
    if (label_id) {
      if (labelName !== "" && labelColor !== "") {
        const myFormData = {
          labelName: labelName,
          labelColor: labelColor,
        };

        http
          .post("update-user-label-properties/" + label_id, myFormData)
          .then(({ data }) => {
            toast.success(data.msg);
            if (data.status == 1) {
              setUserdetailLabels(
                userdetailLabels?.map((label) => {
                  if (label.id === label_id) {
                    return {
                      ...label,
                      label_name: labelName,
                      label_color: labelColor,
                    };
                  }
                  return label;
                })
              );
              setEditLAbel({ show: false, item: null });
              setLabelName("");
              setLabelColor("");
            }
          })
          .catch((error) => {});
      } else {
        toast.error("Please select at least one label property.");
      }
    } else {
      toast.error("Invalid label");
    }

    // Reset input fields
  };
  const postLabel = () => {
    if (labelName !== "" && labelColor !== "") {
      const myFormData = {
        labelName: labelName,
        labelColor: labelColor,
      };

      http
        .post("create-user-label-properties", myFormData)
        .then(({ data }) => {
          console.log(data);
          toast.success(data.msg);
          if (data.status == 1) {
            setLabelName("");
            setLabelColor("");
            setLabels([...labels, { name: labelName, color: labelColor }]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      toast.error("Please select at least one label property.");
    }
    console.log("Label Name:", labelName);
    console.log("Label Color:", labelColor);
  };
  const renderLabels = () => {
    return labels.map((label, index) => (
      <div
        key={label.id + 12789100000000654}
        className="label_btn"
        style={{ backgroundColor: label.color }}
        onClick={() => handleSubmitLabel(label.name)}
      >
        <span>{label.name}</span>
      </div>
    ));
  };
  const fetchUserLabels = () => {
    http
      .post("get-user-label-properties", "")
      .then(({ data }) => {
        console.log(data);
        setUserdetailLabels(data.labels);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    fetchUserLabels();
  }, []);

  const handlePdfPropertyDetails = () => {
    setIsDownloading(true);
    http
      .post("property-detail-pdf", doObjToFormData({ propertyId }), {
        responseType: "blob",
      })
      .then(({ data }) => {
        setIsDownloading(false);
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        let filename = propertyData.address
          ? `${propertyData.address} details.pdf`
          : "property-details.pdf";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.error(error);
      });
  };
  const total_available_credits = useSelector(
    (state) => state.fetchSiteSettings.total_available_credits
  );
  const dispatch = useDispatch();
  const handlePrintPropertyDetails = () => {
    if (userAvailableCredits > 0) {
      setIsPrinting(true);
      http
        .post("property-detail-pdf", doObjToFormData({ propertyId }), {
          responseType: "arraybuffer",
        })
        .then(({ data }) => {
          setIsPrinting(false);
          const blob = new Blob([data], { type: "application/pdf" });
          const href = URL.createObjectURL(blob);
          const printWindow = window.open(href);

          printWindow.onload = () => {
            printWindow.focus();
            printWindow.print();
            URL.revokeObjectURL(href);
          };
          dispatch(
            updateMEmberCredits(parseInt(userAvailableCredits) - 1, propertyId)
          );
          // dispatch(updateMEmberCredits(parseInt(total_available_credits) - 1))
        })
        .catch((error) => {
          setIsPrinting(false);
          console.error(error);
        });
    } else {
      toast.error("Credits are not enough to perform this action!");
    }
  };

  const handleNearbyPropertiesClick = (zip) => {
    localStorage.setItem("search-nearby", true);
    window.location.href = `/search-property?search_query=${zip}&search_nearby=yes&search_type=zipcode`;
    // document.getElementById("search_field_id").value = "Saad Dev";
    // document.getElementById("search_field").submit();
  };

  const labelDrop = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!labelDrop.current.contains(e.target)) {
        setLabel(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  const handleOpenInNewTab = (e) => {
    e.preventDefault();
    window.open(`/property-details/${propertyId}`, "_blank");
  };
  return (
    <>
      <div
        className={
          hideSearch
            ? "result_filter detail_filter_flex less_top"
            : "result_filter detail_filter_flex"
        }
      >
        <div className="contain-fluid big_contain_fluid">
          <div className="filter_result_flex detail_filter_result_flex">
            <div className="_col">
              <div className="filter_blk">
                <div className="back_lbl_nav">
                  <Link to="/search-property" onClick={goBack}>
                    <img src="/images/back_arrow.svg" alt="" />{" "}
                    <span>Property List</span>
                  </Link>
                </div>
                <div class="drop_filter drop_result_btn" ref={labelDrop}>
                  <button
                    class="filter_btn site_btn blank yellow_blank"
                    onClick={ToggleLabel}
                  >
                    <span>Label</span>
                    <img src="/images/label_circle.svg" alt="" />
                  </button>
                  {label && (
                    <div className="drop_cnt medium active">
                      <div className="head_label_flex">
                        <span>Label propertiess</span>
                        <input type="text" defaultValue={"1"} />
                        <span>to</span>
                        <input type="text" defaultValue={"2"} />
                      </div>
                      <div className="flex_label_new_new">
                        <div className="col_left_lbl">
                          {userdetailLabels.map((label, index) => (
                            <div className="_col_left">
                              <div
                                key={label.id + 127891000000004563}
                                className="label_btn"
                                style={{ backgroundColor: label.label_color }}
                                onClick={() =>
                                  handleSubmitLabel(label.label_name)
                                }
                              >
                                <span>{label.label_name}</span>
                                <span>({index + 1})</span>
                              </div>
                              <div className="col_right_lbl">
                                <button
                                  type="button"
                                  onClick={(e) => handleShowEditLabel(e, label)}
                                >
                                  <img src="/images/pencil.svg" alt="" />
                                </button>
                              </div>
                            </div>
                          ))}
                          {renderLabels()}
                        </div>
                      </div>
                      {editLabel?.show === true && editLabel?.item !== null ? (
                        <div className="new_label_new_new edit_label">
                          <div
                            className="crosBtn"
                            onClick={handleCloseEditLabel}
                          >
                            x
                          </div>
                          <div className="flex">
                            <input
                              type="text"
                              name="label_name"
                              placeholder="Label Name"
                              value={labelName}
                              className="input"
                              onChange={handleChangeEditLabel}
                            />

                            <input
                              type="color"
                              name="label_color"
                              value={labelColor}
                              className="new_clr_choose"
                              onChange={handleChangeEditLabel}
                            />
                          </div>
                          <div className="btnBlk flex">
                            <DeleteLabel
                              label_id={editLabel?.item?.id}
                              setUserLabels={setUserdetailLabels}
                              setEditLAbel={setEditLAbel}
                            />
                            <button
                              onClick={(e) =>
                                updateLabel(e, editLabel?.item?.id)
                              }
                              className="site_btn"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="new_lbl_plus_sign">
                        <button onClick={showLabelInput}>New label +</button>
                        {showNewLabelInput && (
                          <div>
                            <input
                              type="text"
                              name="label_name"
                              placeholder="Label Name"
                              value={labelName}
                              className="input"
                              onChange={handleLabelNameChange}
                            />
                            <input
                              type="color"
                              name="label_color"
                              value={labelColor}
                              onChange={handleLabelColorChange}
                            />
                            <button onClick={postLabel}>Save</button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div class="drop_filter">
                  <button
                    class="filter_btn site_btn"
                    type="button"
                    onClick={() =>
                      handleNearbyPropertiesClick(propertyData.zip)
                    }
                  >
                    <span>Nearby properties</span>
                    <img src="/images/building.svg" alt="" />
                  </button>
                </div>
                <div class="drop_filter">
                  <button
                    class="filter_btn site_btn blank yellow_blank"
                    onClick={handlePrintPropertyDetails}
                    disabled={isPrintiing}
                  >
                    <FormProcessingSpinner isFormProcessing={isPrintiing} />
                    <span>Print</span>
                    <img src="/images/report_circle.svg" alt="" />
                  </button>
                </div>
                <div class="drop_filter">
                  <a class="filter_btn site_btn" href="/search-property">
                    Reset Search
                  </a>
                </div>
                {/* <div class="drop_filter">
                  <button
                    class="filter_btn site_btn blank yellow_blank"
                    onClick={handlePdfPropertyDetails}
                    disabled={isDownloading}
                  >
                    <FormProcessingSpinner isFormProcessing={isDownloading} />
                    <span>Download PDF</span>
                    <img src="/images/download_icon.svg" alt="" />
                  </button>
                </div> */}
                {/* <div
                  class="drop_filter drop_result_btn cell_top_high_mrgn"
                  ref={sortByDrop}
                >
                  <button
                    class="filter_btn site_btn blank yellow_blank"
                    onClick={ToggleSort}
                  >
                    <span>Sort by: {sortValue === 'year' ? 'Year Built' : sortValue === 'lot_area' ? 'Lot Area' : sortValue === 'sale_price' ? 'Sale Price' : sortValue === 'property_type' ? 'Property Type' : sortValue === 'last_sale_date' ? "Last Sale Date" : sortValue === 'tax_year' ? 'Tax Year' : sortValue === 'tax_amount' ? "Tax Amount" : sortValue === 'property_sf' ? "Property square footage" : ""}</span>
                    <img src="/images/filter_circle.svg" alt="" />
                  </button>
                  <div
                    className={
                      sort
                        ? "drop_cnt height_cnt_drop_bdy exact_wide active"
                        : "drop_cnt height_cnt_drop_bdy exact_wide"
                    }
                  >
                    <div className="option_lst">
                      <button onClick={(e) => selectSortValue(e, 'property_type')} className={sortValue == 'property_type' ? "active" : ""}>Property Type</button>
                      <button onClick={(e) => selectSortValue(e, 'lot_area')} className={sortValue == 'lot_area' ? "active" : ""}>Lot Area</button>
                      <button onClick={(e) => selectSortValue(e, 'last_sale_date')} className={sortValue == 'last_sale_date' ? "active" : ""}>Last Sale Date</button>
                      <button onClick={(e) => selectSortValue(e, 'sale_price')} className={sortValue == 'sale_price' ? "active" : ""}>Sales Price</button>
                      <button onClick={(e) => selectSortValue(e, 'year')} className={sortValue == 'year' ? "active" : ""}>Year Built</button>
                      <button onClick={(e) => selectSortValue(e, 'tax_year')} className={sortValue == 'tax_year' ? "active" : ""}>Tax Year</button>
                      <button onClick={(e) => selectSortValue(e, 'tax_amount')} className={sortValue == 'tax_amount' ? "active" : ""}>Tax Amount</button>
                      <button onClick={(e) => selectSortValue(e, 'property_sf')} className={sortValue == 'property_sf' ? "active" : ""}>Property square footage</button>
                    </div>
                  </div>
                </div> */}
                <button
                  className="site_btn hide_detail_search_btn"
                  onClick={ToggleHideSearch}
                >
                  <span>View Search</span>
                  <img src="/images/eye_circle_hide.svg" alt="" />
                </button>
              </div>
            </div>

            <div className="_col">
              <div className="filter_blk">
                <button
                  class="filter_btn site_btn blank yellow_blank"
                  onClick={handleOpenInNewTab}
                >
                  <span>Open in new tab</span>
                  <img src="/images/minimize_icon.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailFilters;
