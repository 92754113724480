import React, { useEffect } from "react";
import Banner from "./Banner";
import Detail from "./Detail";

import { fetchPrivacy } from "../../../states/actions/fetchPrivacy";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useLocation } from "react-router-dom";
import MetaGenerator from "../../common/meta-generator";

const SubscriptionAgreement = ({ page_name }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchPrivacy.content);
  const isLoading = useSelector((state) => state.fetchPrivacy.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, meta } = data;

  useEffect(() => {
    dispatch(fetchPrivacy({ page_name: page_name }));
  }, []);
  // useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <Banner content={content} />
          <Detail content={content} />
        </>
      )}
    </>
  );
};

export default SubscriptionAgreement;
