import React, { useState, useMemo, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Text from "../../common/Text";
import SubmitButton from "../../common/SubmitButton";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as paths from "../../../constants/paths";
import http from "../../../helpers/http";
import { makeSubsPayment } from "../../../states/actions/fetchCheckout";
import { checkoutTrialDate } from "../../../helpers/helpers";
import TermsPopup from "./termsPopup";
import Captcha from "../../common/captcha";
import { toast } from "react-toastify";

const CSS_STYLES = `
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
`;

const useOptions = () => {
  const options = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          display: "block",
          width: "100%",
          height: "5.3rem",
          fontFamily: "'Red Hat Display', sans-serif",
          fontWeight: "500",
          color: "#061237",
          background: "#fff",
          "text-align": "left",
          padding: "0.6rem 1.4rem",
          "::placeholder": {
            color: "#130a2952",
            fontSize: "15px",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const Form = ({
  content,
  plan,
  subscription,
  terms_conditions,
  subscription_agreement,
  enterprise = false,
}) => {
  const [captchaToken, setCaptchaToken] = useState(null);
  const options = useOptions();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { plan_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isFormProcessing = useSelector(
    (state) => state.fetchCheckout.isFormProcessing
  );
  const [cardError, setCardError] = useState(null);
  const [cardLoading, setCardLoading] = useState(false);
  const {
    register,
    watch,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
  } = useForm();
  const watchAllFields = watch();
  const handleFormSubmit = (data) => {
    if (!captchaToken) {
      toast.error("Please verify the CAPTCHA", TOAST_SETTINGS);
      return;
    }
    setCardLoading(true);
    data = { ...data, action: searchParams.get("action") };
    handleSubmitCard(data);

    // if (subscription?.id > 0 && subscription?.plan_id !== plan?.id) {
    //   let newData = {
    //     ...data,
    //     plan_id: plan_id,
    //     action: action
    //   };
    //   // console.log(newData);
    //   dispatch(makeSubsPayment(newData));
    // } else {
    //   data = { ...data, action };
    //   handleSubmitCard(data);
    // }
  };
  const handleCardDetailsChange = (ev) => {
    ev.error ? setCardError(ev.error.message) : setCardError();
  };
  const handleSubmitCard = async (post) => {
    setCardError(null);

    if (!stripe || !elements) {
      return;
    }

    const card_Element = elements.getElement(CardElement);
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: card_Element,
        // billing_details: billingDetails
      });
      if (paymentMethodReq.error) {
        setCardError(paymentMethodReq.error.message);
        setCardLoading(false);
        return false;
      } else {
        var form_data = new FormData();
        for (var key in post) {
          form_data.append(key, post[key]);
        }
        form_data.append("payment_method", paymentMethodReq.paymentMethod.id);
        form_data.append("plan_id", plan_id);
        form_data.append("token", localStorage.getItem("authToken"));
        form_data.append("host", window.location.hostname);
        form_data.append("captcha_token", captchaToken);
        http
          .post(`${paths.API_BASE_URL}create-payment-intent`, form_data)
          .then((data) => {
            if (data?.data?.status === 1) {
              const {
                subscriptionId,
                clientSecret,
                clientSecretSetup,
                payment_intent,
                customerId,
              } = data.data;
              let card_result = stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                  card: card_Element,
                },
              });

              if (card_result.error) {
                setCardError(card_result.error.message);
                setCardLoading(false);
                return false;
              } else {
                card_result.then((response) => {
                  // console.log("response",response);return
                  if (response.error) {
                    setCardError(response.error.message);
                    setCardLoading(false);
                    return false;
                  } else {
                    chargePayment(
                      post,
                      subscriptionId,
                      customerId,
                      response?.paymentIntent,
                      paymentMethodReq.paymentMethod.id
                    );
                  }
                });
              }
            } else {
              setCardError(data.data.msg);
              setCardLoading(false);
              return false;
            }
          });
      }
    } catch (err) {
      setCardError(err.message);
      setCardLoading(false);
    }
  };

  const chargePayment = async (
    post,
    subscriptionId,
    customerId,
    paymentIntent,
    payment_method_id
  ) => {
    let formValues = post;
    let newData = {
      ...formValues,
      subscriptionId: subscriptionId,
      customer_id: customerId,
      plan_id: plan_id,
      paymentIntent: paymentIntent?.id ? paymentIntent?.id : "",
      payment_method_id: payment_method_id,
      host: window.location.hostname,
    };

    dispatch(makeSubsPayment(newData));
  };
  const handleYearlyButtonClick = async () => {
    const formData = {
      planId: plan.id,
    };
    http
      .post("year-month", formData)
      .then(({ data }) => {
        if (searchParams.get("action")) {
          window.location.href = `/checkout/${
            data.plan_id
          }?action=${searchParams.get("action")}`;
        } else {
          window.location.href = `/checkout/${data.plan_id}`;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const checkTermsAndConditions = () => {
    if (!captchaToken) {
      toast.error("Please verify the CAPTCHA", TOAST_SETTINGS);
      return;
    }
  };
  const [termsPopup, setTermsPopup] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  useEffect(() => {
    if (isTermsAccepted) {
      setTermsPopup(false);
    }
  }, [isTermsAccepted]);
  let action = searchParams.get("action");
  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="blk_form">
          <h6>Choose your plan</h6>
          <div className="form_blk">
            <div className="check_box_pkgs_choose">
              <input
                type="radio"
                name="package"
                id="billed_mnthly"
                className="hidden"
              />
              <label htmlFor="billed_mnthly">
                <span>
                  Billed{" "}
                  {plan?.plan_interval === "month"
                    ? "Monthly"
                    : plan?.plan_interval === "year"
                    ? "Yearly"
                    : ""}
                </span>
                <strong>
                  $ {plan?.price}/
                  {plan?.plan_interval === "month" || plan?.type === "monthly"
                    ? "mo"
                    : plan?.plan_interval === "year" || plan?.type === "annual"
                    ? "yr"
                    : ""}
                </strong>
              </label>
            </div>
            <br></br>
            {!enterprise ? (
              <div className="check_box_pkgs_choose button-container">
                <h6>Please Select Type</h6>
                <div className="button-wrapper">
                  <SubmitButton
                    classes={`site_btn ${
                      plan?.plan_interval === "month" ? " " : "blank"
                    } `}
                    button_text="Monthly"
                    type="button"
                    onClick={handleYearlyButtonClick}
                    disabled={plan?.plan_interval === "month"}
                  />
                  <SubmitButton
                    button_text="Yearly"
                    classes={`site_btn ${
                      plan?.plan_interval === "year" ? " " : "blank"
                    } `}
                    type="button"
                    onClick={handleYearlyButtonClick}
                    disabled={plan?.plan_interval === "year"}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <style>{CSS_STYLES}</style>
          </div>
        </div>
        <div className="blk_form date_ex_flex">
          <h6>Credit card</h6>
          <Link to="/" className="mini_quest">
            Why do we need this?
          </Link>
          <CardElement options={options} onChange={handleCardDetailsChange} />
          {cardError && <p className="validation_error">{cardError}</p>}
          {/* <span className="validation-error">{cardError}</span> */}
        </div>
        <h6 className="checkout_sm_heading">Billing address</h6>
        <div className="blk_form">
          <h6>Card holder name</h6>
          <div className="form_blk">
            <input
              type="text"
              className="input"
              placeholder="Name"
              {...register("name_on_card", {
                required: "Card holder name is required.",
              })}
            />
            <span className="validation-error">
              {errors.name_on_card?.message}
            </span>
          </div>
        </div>
        <div className="blk_form">
          <h6>Address</h6>
          <div className="form_blk">
            <input
              type="text"
              className="input"
              placeholder="Address"
              {...register("address", {
                required: "Address is required.",
              })}
            />
            <span className="validation-error">{errors.address?.message}</span>
          </div>
        </div>
        <div className="blk_form">
          <h6>City</h6>
          <div className="form_blk">
            <input
              type="text"
              className="input"
              placeholder="City"
              {...register("city", {
                required: "City is required.",
              })}
            />
            <span className="validation-error">{errors.city?.message}</span>
          </div>
        </div>
        <div className="flex form_flex_half">
          <div className="blk_form">
            <h6>State</h6>
            <div className="form_blk">
              <input
                type="text"
                className="input"
                placeholder="state"
                {...register("state", {
                  required: "State is required.",
                })}
              />
              <span className="validation-error">{errors.state?.message}</span>
            </div>
          </div>
          <div className="blk_form">
            <h6>Zip code</h6>
            <div className="form_blk">
              <input
                type="text"
                className="input"
                placeholder="Zip code"
                {...register("zip_code", {
                  required: "Zip code is required.",
                })}
              />
              <span className="validation-error">
                {errors.zip_code?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="small_txt agree_smll mb-3">
          <div className="txtGrp flex">
            <div className="lblBtn flex accept_blk">
              <input
                type="checkbox"
                name="property_recommendation_updates"
                id="property_recommendation_updates"
                {...register("property_recommendation_updates", {
                  required: "Required",
                })}
              />
              <label htmlFor="property_recommendation_updates">
                I consent to receive email communication from Rhino Recon about
                marketing, and property recommendation updates.{" "}
              </label>
            </div>
            <span className="validation-error">
              {errors.property_recommendation_updates?.message}
            </span>
          </div>
        </div>
        <div className="line_br"></div>
        <div className="blk_form">
          <Captcha onVerify={(token) => setCaptchaToken(token)} />
        </div>
        <div className="line_br"></div>
        <div className="btn_blk">
          {isTermsAccepted === true ? (
            <SubmitButton
              button_text={content.left_sec_button_text_1}
              classes="site_btn"
              isFormProcessing={
                !stripe || !elements || cardLoading || isFormProcessing
              }
            />
          ) : watchAllFields?.name_on_card === undefined ||
            watchAllFields?.name_on_card === "" ||
            watchAllFields?.name_on_card === null ||
            watchAllFields?.address === undefined ||
            watchAllFields?.address === "" ||
            watchAllFields?.address === null ||
            watchAllFields?.city === undefined ||
            watchAllFields?.city === "" ||
            watchAllFields?.city === null ||
            watchAllFields?.state === undefined ||
            watchAllFields?.state === "" ||
            watchAllFields?.state === null ||
            watchAllFields?.zip_code === undefined ||
            watchAllFields?.zip_code === "" ||
            watchAllFields?.zip_code === null ||
            watchAllFields?.property_recommendation_updates === undefined ||
            watchAllFields?.property_recommendation_updates === "" ||
            watchAllFields?.property_recommendation_updates === null ||
            watchAllFields?.property_recommendation_updates === false ||
            watchAllFields?.property_recommendation_updates === "false" ? (
            <button
              type="button"
              className="site_btn"
              onClick={() => {
                trigger();
              }}
            >
              {searchParams.get("action")
                ? "Subscribe"
                : content.left_sec_button_text_1}
            </button>
          ) : !captchaToken ? (
            <button
              type="button"
              className="site_btn"
              onClick={() => {
                checkTermsAndConditions();
              }}
            >
              {searchParams.get("action")
                ? "Subscribe"
                : content.left_sec_button_text_1}
            </button>
          ) : (
            <button
              type="button"
              className="site_btn"
              onClick={() => setTermsPopup(true)}
            >
              {searchParams.get("action")
                ? "Subscribe"
                : content.left_sec_button_text_1}
            </button>
          )}
        </div>

        {!action ? (
          <div class="small_txt">
            <img src="/images/danger_circle.svg" alt="" />
            <small>
              You will not be charged until your free trial ends on{" "}
              {checkoutTrialDate()}
            </small>
          </div>
        ) : (
          ""
        )}

        {/* <div className="agree_blk_form">
          <Text string={content.left_sec_terms_and_cond_text} />
        </div> */}
      </form>
      {termsPopup ? (
        <TermsPopup
          terms_conditions={terms_conditions}
          subscription_agreement={subscription_agreement}
          setTermsPopup={setTermsPopup}
          setIsTermsAccepted={setIsTermsAccepted}
          isTermsAccepted={isTermsAccepted}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Form;
