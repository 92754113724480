import React, { useState, useRef, useEffect } from "react";
import FiltersSearch from "./Filters-search";
import MobileAdvance from "./Mobile-advance";
import SearchMap from "./Search-map";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

const SearchProperty = () => {
const [canSaveScroll, setCanSaveScroll] = useState(false);
  useEffect(() => {
    const onPageLoad = () => {
      const savedScrollPosition = localStorage.getItem("scrollPosition");

      if (savedScrollPosition) {
        // Add a delay to ensure content is fully loaded
        setTimeout(() => {
          window.scrollTo(0, parseInt(savedScrollPosition, 10));
          // alert("triggered")
        }, 4000);
        
        // Start tracking new scroll only after reaching stored position
        const checkScroll = () => {
          if (window.scrollY >= parseInt(savedScrollPosition, 10)) {
            setCanSaveScroll(true);
            window.removeEventListener("scroll", checkScroll);
          }
        };

        window.addEventListener("scroll", checkScroll);

        return () => {
          window.removeEventListener("scroll", checkScroll);
        };
      } else {
        setCanSaveScroll(true);
      }
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
    }

    return () => {
      window.removeEventListener("load", onPageLoad);
    };
  }, []);

  useEffect(() => {
    if (!canSaveScroll) return;

    const handleScroll = () => {
      localStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [canSaveScroll]);
  const [searchParams] = useSearchParams();
  const search_keyword = searchParams.get("search_p");
  // console.log("search_keyword",search_keyword)
  const [hideSearch, setHideSearch] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [resultView, setResultView] = useState(true);
  const [drawnFeatures, setDrawnFeatures] = useState(null);
  // console.log("showMap",showMap)
  // console.log("drawnFeatures",drawnFeatures)

  const searchRef = useRef(null);
  const handleSearchClick = () => {
    if (searchRef.current) {
      searchRef.current.click();
    }
  };
  const ToggleHideSearch = () => {
    setHideSearch(!hideSearch);
  };

  const Maptoggle = () => {
    setShowMap(!showMap);
  };
  useDocumentTitle("Search Properties -  Rhino Recon");
  return (
    <>
      <MobileAdvance />

      <FiltersSearch
        ToggleHideSearch={ToggleHideSearch}
        Maptoggle={Maptoggle}
        hideSearch={hideSearch}
        showMap={showMap}
        setShowMap={setShowMap}
        drawnFeatures={drawnFeatures}
        setDrawnFeatures={setDrawnFeatures}
        searchRef={searchRef}
        search_keyword={search_keyword}
      />

      <SearchMap
        hideSearch={hideSearch}
        showMap={showMap}
        setDrawnFeatures={setDrawnFeatures}
        drawnFeatures={drawnFeatures}
        handleSearchClick={handleSearchClick}
        setshowMap={setShowMap}
        Maptoggle={Maptoggle}
      />
    </>
  );
};

export default SearchProperty;
