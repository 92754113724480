import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ onVerify }) => {
  const [verified, setVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    if (value) {
      setVerified(true);
      if (onVerify) onVerify(value); // Pass token to parent component
    }
  };

  const handleCaptchaExpire = () => {
    setVerified(false);
    if (onVerify) onVerify(""); // Pass empty value when expired
  };

  return (
    <div>
      <ReCAPTCHA
        sitekey="6LdCfugqAAAAABXRJTxJ3rgcc8bQmC049D8LHLq_" // Replace with your actual site key
        onChange={handleCaptchaChange}
        onExpired={handleCaptchaExpire} // Handle expiration
      />
    </div>
  );
};

export default Captcha;
