import http from "../../helpers/http";
import httpBlob from "../../helpers/http-blob";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_DATA_UPLOAD_CONTENT,
  FETCH_DATA_UPLOAD_CONTENT_SUCCESS,
  FETCH_DATA_UPLOAD_CONTENT_FAILED,
  UPLOAD_DATA,
  UPLOAD_DATA_SUCCESS,
  UPLOAD_DATA_FAILED,
} from "./actionTypes";

export const fetchDataUpload = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_DATA_UPLOAD_CONTENT,
    payload: null
  });
  http
    .post("upload-data-page", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: FETCH_DATA_UPLOAD_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      localStorage.clear();
      window.location.reload();
      dispatch({
        type: FETCH_DATA_UPLOAD_CONTENT_FAILED,
        payload: error
      });
    });
};

export const uploadData = (file) => (dispatch) => {
  let formData = { token: localStorage.getItem("authToken") };
  formData = helpers.doObjToFormData(formData);

  if (typeof file != "undefined") formData.append("file", file[0]);

  dispatch({
    type: UPLOAD_DATA,
    payload: null
  });
  httpBlob
    .post("upload-data", formData)
    .then(({ data }) => {
      document.getElementById('upload-user-data').value=''
      if (data.status) {
        toast.success("File uploaded successfully! Empty records will be ignored.", TOAST_SETTINGS);
        dispatch({
          type: UPLOAD_DATA_SUCCESS,
          payload: data
        });
      } else {
          toast.error(
            <Text string={data.msg} parse={true} />,
            TOAST_SETTINGS
          );
          dispatch({
            type: UPLOAD_DATA_FAILED,
            payload: null
          });
      }
    })
    .catch((error) => {
      document.getElementById('upload-user-data').value=''
      dispatch({
        type: UPLOAD_DATA_FAILED,
        payload: error
      });
    });
};
