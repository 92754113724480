import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadData } from "../../../states/actions/fetchDataUpload";
import SubmitButton from "../../common/SubmitButton";
import { eventDateFormat } from "../../../helpers/helpers";

const UploadTables = () => {
  const [file, setFile] = useState("");
  const fileRef = useRef(null);
  const dispatch = useDispatch();
  const isProcessing = useSelector(
    (state) => state.fetchDataUpload.isProcessing
  );
  const records = useSelector((state) => state.fetchDataUpload.records);
  const action = useSelector((state) => state.fetchDataUpload.action);

  const handleFileSelect = (e) => {
    e.preventDefault();
    fileRef.current.click();
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e);
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    dispatch(uploadData(file.target.files));
  };

  useEffect(() => {
    if (action) setFile("");
  }, [action]);

  return (
    <>
      <section className="upload_table">
        <div className="contain">
          {/* <a
            href={`${process.env.REACT_APP_API_BASE_URL}storage/csv/sample-data-upload.csv`}
            download="filename"
          >
            Download Sample
          </a> */}
          <div className="table_blk">
            <div className="header_tbl flex">
              <h5>Uploads</h5>
              <div className="btn_blk">
                <a
                    href={`${process.env.REACT_APP_API_BASE_URL}storage/csv/sample-data-upload.csv`}
                    download="filename" className="site_btn"
                  >
                  Download Sample
                </a>
                <button
                  className="site_btn"
                  onClick={handleFileSelect}
                  disabled={isProcessing}
                >
                  {file ? file?.target?.files[0]?.name : "Upload File"}
                </button>
                {file && (
                  <SubmitButton
                    button_text="Upload"
                    classes="site_btn"
                    isFormProcessing={isProcessing}
                    type="button"
                    onClick={handleFileUpload}
                  />
                )}
                <input
                  type="file"
                  id="upload-user-data"
                  name="file"
                  hidden
                  onChange={handleFileChange}
                  ref={fileRef}
                />
                
              </div>
            </div>
            <div className="inner_tbl">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>File</th>
                  </tr>
                </thead>
                <tbody>
                  {records && records.length > 0 ? (
                    records.map((row) => (
                      <tr>
                        <td>{eventDateFormat(row.created_at)}</td>
                        <td>{row.status == 1 ? "Completed" : "Failed"}</td>
                        <td>
                          <a href={`${process.env.REACT_APP_API_BASE_URL}storage/user_uploads/${row?.file_name}`} download>Download</a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>The data files you have uploaded will show here.</td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadTables;
