import React from "react";
import { motion } from "framer-motion";
import ReactCodeInput from "react-verification-code-input";
import {
  verifyLogin
  // resendOtpCode
} from "../../../states/actions/fetchSignin";
import { useDispatch, useSelector } from "react-redux";
import FetchingStatus from "../../common/FetchingStatus";
import { useLocation } from "react-router-dom";

const AuthenticationLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isFormProcessing = useSelector(
    (state) => state.fetchSignin.isFormProcessing
  );
  const isResendingOtp = useSelector(
    (state) => state.fetchSignin.isResendingOtp
  );
  const handleComplete = (data) => {
    let formData = {
      otp: data,
      email: localStorage.getItem("email")
    };
    dispatch(verifyLogin(formData, location));
  };

  // const handleResetOtpCode = () => {
  //   dispatch(
  //     resendOtpCode({
  //       email:
  //         member !== false ? member?.mem_email : localStorage.getItem("email")
  //     })
  //   );
  // };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, times: [2, 3, 2] }}
        exit={{ opacity: 0 }}
      >
        <fieldset disabled={isFormProcessing || isResendingOtp}>
          <h4>Login Verification</h4>
          <p>Please enter the verification code sent to your email.</p>
          <div className="blk_form">
            <div class="form_blk">
              <ul className="code_verify">
                <li>
                  <ReactCodeInput
                    autoFocus={true}
                    onComplete={handleComplete}
                  />
                </li>
              </ul>
              {isFormProcessing && (
                <FetchingStatus message="Verifying your otp. Please wait..." />
              )}
              {isResendingOtp && (
                <FetchingStatus message="Resending otp. Please wait..." />
              )}
            </div>
          </div>
          <div className="dont_acc">
            {/* <p>
              Didn’t get code?{" "}
              <a href="javascript:void(0)" onClick={handleResetOtpCode}>
                Resend code
              </a>
            </p>*/}
          </div>
          {/* <div className="br"></div>
          <div className="btn_blk">
            <button
              className="site_btn"
              type="button"
              onClick={() => {
                alert("You've successfully submitted this form");
              }}
            >
              Verify
            </button>
            </div> */}
        </fieldset>
      </motion.div>
    </>
  );
};

export default AuthenticationLogin;
