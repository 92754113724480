import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ToastContainer, toast } from "react-toastify";
import * as helpers from "../../../helpers/helpers";
import http from "../../../helpers/http";
import LoadingScreen from "../../common/LoadingScreen";
import MultiRangeSlider from "../multirangeslider/MultiRangeSlider";
import SearchResultsComponent from "./Result-search";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { setSearchQuery } from "../../../states/actions/searchQueryActions";
import { FETCH_PROPERTIES_CONTENT_FAILED } from "../../../states/actions/actionTypes";
import { toggleResultView } from "../../../states/actions/resultView";
import usePreviousURLParams from "./prevurl";

const FiltersSearch = (props) => {
  const searchInputRef = useRef(null);
  const [searchParams] = useSearchParams();

  const [search_p, setSearchP] = useState("");
  const [searchQueryValues, setSearchQueryValues] = useState(null);
  const dispatch = useDispatch();

  const handleSearchQueryChange = (newQuery) => {
    setSearchQueryValues(newQuery); // Update local state
    dispatch(setSearchQuery(newQuery)); // Update Redux store
  };
  useEffect(() => {
    // Extract query parameters from the URL
    const paramsObject = Object.fromEntries([...searchParams.entries()]);

    // Call handleSearchQueryChange to update state and Redux store
    handleSearchQueryChange(paramsObject);
  }, [searchParams, dispatch]); // Re-run this effect when the searchParams change

  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split("/");
  useEffect(() => {
    if (props?.search_keyword) {
      setSearchP(props?.search_keyword);
    }
  }, [props?.search_keyword]);

  const queryParams = new URLSearchParams(location.search);

  const propertyTypeExists = searchQueryValues?.hasOwnProperty("property_type");
  const [sortValue, setSortValue] = useState("sale_price");
  const [selectedOption, setSelectedOptions] = useState({});
  const [includeLabels, setIncludeLabels] = useState([]);
  const [excludeLabels, setExcludeLabels] = useState([]);
  const [multSelectOptions, setMultiSelectOptions] = useState({});
  const removeOption = (keyToRemove) => {
    setMultiSelectOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      delete newOptions[keyToRemove];
      return newOptions;
    });
  };
  const tabs = [
    "Property type",
    "Building & Lot",
    "Owner",
    "Sales",
    "Debt",
    "Tax",
    "My Properties",
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    // let previousURLParams = '';
    // const referrerURL = document.referrer;
    // if (referrerURL) {
    //   const url = new URL(referrerURL);
    //   previousURLParams = url?.pathname;
    // }

    // if (previousURLParams == '/saved-searches') {
    //   if (queryParams.get("geometry")) {
    //     props.setDrawnFeatures(queryParams.get("geometry"))
    //   }

    // }
    if (
      segments[1] !== "owner-property" &&
      segments[1] !== "owner-overview" &&
      segments[1] !== "property-details"
    ) {
      const allParams = {};
      for (const [key, value] of queryParams.entries()) {
        allParams[key] = value;
      }
      if (searchQueryValues?.mortgage_amount) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          mortgage_amount: searchQueryValues?.mortgage_amount,
        }));
        allParams.mortgage_amount = searchQueryValues?.mortgage_amount;
      }
      if (searchQueryValues?.sales_price) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          sales_price: searchQueryValues?.sales_price,
        }));
        allParams.sales_price = searchQueryValues?.sales_price;
      }
      if (searchQueryValues?.portfolio_assessed_value) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          portfolio_assessed_value: searchQueryValues?.portfolio_assessed_value,
        }));
        allParams.portfolio_assessed_value =
          searchQueryValues?.portfolio_assessed_value;
      }
      if (searchQueryValues?.sales_price_sf) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          sales_price_sf: searchQueryValues?.sales_price_sf,
        }));
        allParams.sales_price_sf = searchQueryValues?.sales_price_sf;
      }
      if (Object.keys(allParams).length > 0) {
        if (allParams?.sort) {
          setSortValue(allParams?.sort);
        }

        if (
          allParams?.sale_date_within !== undefined &&
          allParams?.sale_date_within !== null &&
          allParams?.sale_date_within !== ""
        ) {
          setSelectedOptionsWithin((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            sale_date_within: allParams?.sale_date_within,
          }));
        }
        if (
          allParams?.exported_properties !== undefined &&
          allParams?.exported_properties !== null &&
          allParams?.exported_properties !== ""
        ) {
          setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            exported_properties: allParams?.exported_properties,
          }));
        }
        if (
          allParams?.viewed_date !== undefined &&
          allParams?.viewed_date !== null &&
          allParams?.viewed_date !== ""
        ) {
          setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            viewed_date: allParams?.viewed_date,
          }));
        }
        if (
          allParams?.labels_includes_arr !== undefined &&
          allParams?.labels_includes_arr !== null &&
          allParams?.labels_includes_arr !== ""
        ) {
          if (allParams?.labels_includes_arr?.includes(",")) {
            const arr = allParams?.labels_includes_arr
              ?.split(",")
              .map((item) => item.trim());
            setIncludeLabels(arr);
          } else {
            const labels_in = [allParams?.labels_includes_arr];
            setIncludeLabels(labels_in);
          }
        }
        if (
          allParams?.labels_includes_arr !== undefined &&
          allParams?.labels_includes_arr !== null &&
          allParams?.labels_includes_arr !== ""
        ) {
          if (allParams?.labels_includes_arr?.includes(",")) {
            const arr = allParams?.labels_includes_arr
              ?.split(",")
              .map((item) => item.trim());
            setIncludeLabels(arr);
          } else {
            const labels_in = [allParams?.labels_includes_arr];
            setIncludeLabels(labels_in);
          }
        }
        if (
          allParams?.label_exclude !== undefined &&
          allParams?.label_exclude !== null &&
          allParams?.label_exclude !== ""
        ) {
          if (allParams?.label_exclude?.includes(",")) {
            const arr = allParams?.label_exclude
              ?.split(",")
              .map((item) => item.trim());
            setExcludeLabels(arr);
          } else {
            const labels_in = [allParams?.label_exclude];
            setExcludeLabels(labels_in);
          }
        }
        if (
          allParams?.sale_date !== undefined &&
          allParams?.sale_date !== null &&
          allParams?.sale_date !== ""
        ) {
          setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            sale_date: allParams?.sale_date,
          }));
        }
        setSearchQueryValues(allParams);
        if (allParams?.search_query) {
          setSearchP(allParams?.search_query);
        }
        setIsLoadingform(true);
        searchApiReq(allParams);
      }
    }
  }, []);
  const getAllUrlParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const allParams = {};
    for (const [key, value] of queryParams.entries()) {
      allParams[key] = value;
    }
    return allParams;
  };
  const property_details = props?.property_details
    ? props?.property_details
    : false;

  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);

  // useEffect(() => {
  //   if (property_details) {
  //     if (props?.propertyDetailsSortValue) {
  //       setSortValue(props?.propertyDetailsSortValue)
  //       filterSearchSubmitData("search_field", props?.propertyDetailsSortValue)
  //     }
  //   }
  // }, [property_details, props?.propertyDetailsSortValue]);

  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResultsData, setSearchResultsData] = useState(null);
  const [, setTotalPropertiesRecords] = useState(null);
  const [propertyType, setPropertyType] = useState(false);
  const [propertySize, setPropertySize] = useState(false);
  const [recommended, setRecommended] = useState(false);
  const [myProperties, setMyProperties] = useState(false);
  const [moreFilters, setMoreFilters] = useState(false);

  const [selectedOptionWithin, setSelectedOptionsWithin] = useState({});

  const [isLoadingform, setIsLoadingform] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [filters, setFilters] = useState({});
  const popupRef1 = useRef();
  const searchButtonRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [propertyCatsCounts, setPropertyCatsCounts] = useState({});
  const handleLabelIncludeChange = (event) => {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      setIncludeLabels((prevIds) => [...prevIds, checkboxValue]);
    } else {
      setIncludeLabels((prevIds) =>
        prevIds.filter((id) => id !== checkboxValue)
      );
    }
    document.getElementById("labels_text").value = "";
    setExcludeLabels([]);
    if (document.querySelectorAll('input[name="labels_excludes_arr"]')) {
      document
        .querySelectorAll('input[name="labels_excludes_arr"]')
        .forEach(function (checkbox) {
          checkbox.checked = false;
        });
    }
  };
  const handleLabelTextChange = (e) => {
    let labels_text = e.target.value;
    if (labels_text) {
      if (document.querySelectorAll('input[name="labels_excludes_arr"]')) {
        document
          .querySelectorAll('input[name="labels_excludes_arr"]')
          .forEach(function (checkbox) {
            checkbox.checked = false;
          });
      }
      if (document.querySelectorAll('input[name="labels_includes_arr"]')) {
        document
          .querySelectorAll('input[name="labels_includes_arr"]')
          .forEach(function (checkbox) {
            checkbox.checked = false;
          });
      }
    }
  };
  const handleLabelExcludeChange = (event) => {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      setExcludeLabels((prevIds) => [...prevIds, checkboxValue]);
    } else {
      setExcludeLabels((prevIds) =>
        prevIds.filter((id) => id !== checkboxValue)
      );
    }
    document.getElementById("labels_text").value = "";
    setIncludeLabels([]);
    if (document.querySelectorAll('input[name="labels_includes_arr"]')) {
      document
        .querySelectorAll('input[name="labels_includes_arr"]')
        .forEach(function (checkbox) {
          checkbox.checked = false;
        });
    }
  };
  const [ownerContactIncludes, setOwnerContactIncludes] = useState({
    phone_include: false,
    email_include: false,
    mailing_include: false,
  });
  // const [resultView, setResultView] = useState("list");

  // useEffect(() => {
  // }, [ownerContactIncludes]);

  const resultView = useSelector((state) => state.resultView.resultView);
  const [userLabels, setUserLabels] = useState([]);
  const fetchUserLabels = () => {
    http
      .post("get-user-label-properties", "")
      .then(({ data }) => {
        setUserLabels(data.labels);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    fetchUserLabels();
  }, []);
  const navigate = useNavigate();

  const TogglePropertyType = () => {
    setPropertyType(!propertyType);
    if (propertyType) {
      // If the property size tab is already open, close it
      setPropertyType(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(true);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(false);
    }
  };
  const TogglePropertySize = () => {
    if (propertySize) {
      // If the property size tab is already open, close it
      setPropertySize(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(true);
    }
  };
  const ToggleRecommended = () => {
    if (recommended) {
      // If the property size tab is already open, close it
      setRecommended(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(false);
      setRecommended(true);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(false);
    }
  };
  const ToggleMyProperties = () => {
    if (myProperties) {
      // If the property size tab is already open, close it
      setMyProperties(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(true);
      setMoreFilters(false);
      setPropertySize(false);
    }
  };
  const ToggleMoreFilters = () => {
    setMoreFilters(!moreFilters);

    if (moreFilters) {
      // If the property size tab is already open, close it
      setMoreFilters(false);
    } else {
      // If another tab is open, close it first and then open the property size tab
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(true);
      setPropertySize(false);
    }
  };

  const {
    ToggleHideSearch,
    hideSearch,
    Maptoggle,
    showMap,
    ownertoggle,
    setShowMap,
    search_keyword,
    searchRef,
  } = props;
  // useEffect(() => {
  //   if (search_keyword) {
  //     if (searchRef.current) {
  //       searchRef.current.click();
  //     }
  //   }
  // }, [search_keyword]);

  function handleClearForm(event, formId) {
    event.preventDefault();

    if (formId === "property_type") {
      const url = new URL(window.location.href);
      url.searchParams.delete("property_type");

      window.location.href = url.toString();
    }
    if (formId === "more_filters") {
      const url = new URL(window.location.href);
      const form = document.getElementById(formId);

      if (form) {
        // Get all form elements inside #more_filters
        const formElements = form.elements;

        // Loop through form elements and remove their names from the URL
        for (let element of formElements) {
          if (element.name) {
            url.searchParams.delete(element.name);
          }
        }

        // Reset the form fields
        form.reset();
      }

      // Update the URL
      // window.history.pushState({}, "", url.toString());
      window.location.href = url.toString();
    }

    if (formId === "recommended") {
      const url = new URL(window.location.href);
      url.searchParams.delete("year_build");
      url.searchParams.delete("year_build_until");
      url.searchParams.delete("properties_in_portfolio_min");
      url.searchParams.delete("properties_in_portfolio_max");
      url.searchParams.delete("occupaid");
      url.searchParams.delete("recommended_owner_phone_number");
      url.searchParams.delete("recommended_owner_email_address");
      url.searchParams.delete("recommended_owner_property_sq_ft");
      url.searchParams.delete("recommended_owner_portfolio");

      window.location.href = url.toString();
    }
    if (formId === "property_size") {
      const url = new URL(window.location.href);
      url.searchParams.delete("min_unites");
      url.searchParams.delete("max_unites");
      url.searchParams.delete("min_sf");
      url.searchParams.delete("max_sf");
      url.searchParams.delete("min_lotsize");
      url.searchParams.delete("max_lotsize");

      window.location.href = url.toString();
    }
    if (formId === "my_properties") {
      const url = new URL(window.location.href);
      url.searchParams.delete("exported_properties");
      setIncludeLabels([]);

      window.location.href = url.toString();
    }
    if (formId === "building_and_lot") {
      const url = new URL(window.location.href);
      url.searchParams.delete("yearbuilt_from");
      url.searchParams.delete("yearbuilt_until");
      url.searchParams.delete("arealotsf_from");
      url.searchParams.delete("arealotsf_until");
      url.searchParams.delete("zonedcodelocal");

      window.location.href = url.toString();
    }
    if (formId === "owners") {
      const url = new URL(window.location.href);
      url.searchParams.delete("owner_name");
      url.searchParams.delete("owner_type");
      url.searchParams.delete("Owner_occupied");
      url.searchParams.delete("owner_info");
      url.searchParams.delete("reported_owner");
      url.searchParams.delete("reported_owner_address");

      window.location.href = url.toString();
    }
    if (formId === "sales") {
      const url = new URL(window.location.href);
      url.searchParams.delete("sale_date");
      url.searchParams.delete("sale_multi_parcel");
      url.searchParams.delete("min_sales_price");
      url.searchParams.delete("max_sales_price");
      url.searchParams.delete("min_sales_price_sf");
      url.searchParams.delete("max_sales_price_sf");
      url.searchParams.delete("max_sales_price_acr");
      url.searchParams.delete("min_sales_price_acr");

      window.location.href = url.toString();
    }
    if (formId === "mortage_amount") {
      const url = new URL(window.location.href);
      url.searchParams.delete("mortgage_amount");
      url.searchParams.delete("maturity_date");
      url.searchParams.delete("mortage_origination");
      removeOption("mortage_amount");
      window.location.href = url.toString();
    }
    if (formId === "auction_data") {
      const url = new URL(window.location.href);
      url.searchParams.delete("forclosure_properties");

      window.location.href = url.toString();
    }
    if (formId === "yoy_cahange") {
      const url = new URL(window.location.href);
      url.searchParams.delete("tax_min_year");
      url.searchParams.delete("tax_max_year");
      url.searchParams.delete("min_tax_amount");
      url.searchParams.delete("max_tax_amount");

      window.location.href = url.toString();
    }
    if (formId === "labels_form") {
      const url = new URL(window.location.href);
      url.searchParams.delete("labels_includes_arr");
      url.searchParams.delete("labels_includes_arr");
      url.searchParams.delete("label_exclude");
      url.searchParams.delete("viewed_date");
      url.searchParams.delete("exported_properties");
      url.searchParams.delete("note");
      window.location.href = url.toString();
    }
  }

  const handleInputChange = (event, formName) => {
    const { name, value, type, checked } = event.target;
    //setSelectedOption(value);
    let label = event.target.closest("label");
    if (!label && formName !== "property_type") {
      return;
    } else if (formName === "property_type") {
      label = event.target.closest(".lbl_btn")?.querySelector("label");
    }
    const id = label.innerText.trim().replace(/\s/g, "_");

    let inputValue;
    if (type === "radio") {
      inputValue = checked ? value : "";
    } else if (type === "checkbox") {
      const previousValues = formData[name] || [];
      if (checked) {
        inputValue = [...previousValues, value];
      } else {
        inputValue = previousValues.filter((val) => val !== value);
      }
    } else {
      inputValue = value;
    }

    setFormData((prevState) => ({ ...prevState, [name]: inputValue }));
    setSelectedIds((prevState) => [...prevState, id]);

    const selectedValues = Object.entries(formData)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${value}`);
    const data = selectedValues.join("&");

    // update the filter data for the current form
    const filterData = { [id]: selectedValues };
    setFilters((prevState) => ({ ...prevState, [formName]: filterData }));
    // setFormValues(prevValues => ({ ...prevValues, [name]: inputValue }));
  };

  const handleRadioChange = (event, groupName, option) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [groupName]: option,
    }));
  };
  const handleRadioWithinChange = (event, groupName, option) => {
    setSelectedOptionsWithin((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [groupName]: option,
    }));
  };
  const handleMultiSelectChange = (name, min, max) => {
    let arr = [min, max];
    setMultiSelectOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [name]: arr,
    }));
  };

  const handleSetOwnerContactIncludes = (event) => {
    event.preventDefault();
    // setOwnerContactIncludes((prevState) => {
    //   if (prevState.includes(option))
    //     return prevState.filter(function (item) {
    //       return item !== option;
    //     });
    //   return [...prevState, option];
    // });
    setOwnerContactIncludes({
      ...ownerContactIncludes,
      [event.target.value]: event.target.checked,
    });
  };
  const handleSetOwnerContactIncludesLabel = (event, name, value) => {
    event.preventDefault();
    // setOwnerContactIncludes((prevState) => {
    //   if (prevState.includes(option))
    //     return prevState.filter(function (item) {
    //       return item !== option;
    //     });
    //   return [...prevState, option];
    // });
    setOwnerContactIncludes({
      ...ownerContactIncludes,
      [name]: value,
    });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event, "search_field");
    }
  };

  const hanldeSearchFieldSubmit = (event) => {
    event.preventDefault();
    setPage(1);
    handleSubmit(event, "search_field", null, 1, true);
  };
  const [searchPlaceHolder, setSearchPlaceHolder] = useState(
    "Address, city, state, postal code"
  );

  const hanldeSearchSelectFieldSubmit = (event) => {
    event.preventDefault();
    const searchInput = document.getElementsByName("serch")[0];
    if (event.target.value === "city_state") {
      setSearchPlaceHolder("City, State");
    }
    if (event.target.value === "zipcode") {
      setSearchPlaceHolder("Zipcode");
    }
    if (event.target.value === "owner_name") {
      setSearchPlaceHolder("Property Owner");
    }
    if (event.target.value === "address") {
      setSearchPlaceHolder("Address, city, state, postal code");
    }
    // if (searchInputRef.current) {
    //   searchInputRef.current.value = "";
    // }
    // setSearchP("");
  };

  const getQueryFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get("query");
    return query;
  };

  const handlePageClick = (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const all_url_params = {};
    for (const [key, value] of urlParams.entries()) {
      all_url_params[key] = value;
    }

    const geometry = urlParams.get("geometry");
    if (document.getElementById("outer_properties")) {
      window.scroll({
        top: document.getElementById("outer_properties").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }

    let formName1 = localStorage.getItem("setPreviousForm");
    setPage(event.selected + 1);
    urlParams.set("page", event.selected + 1);
    urlParams.set("geometry", geometry);

    // Navigate to the new URL with the updated parameters
    navigate(`/search-property?${urlParams.toString()}`);

    setIsLoadingform(true);
    setFormValues({});

    const form = document.querySelector(`#${formName1}`);
    // if (!form?.checkValidity()) {
    //   // Form is invalid, handle error
    //   return;
    // }
    // const forms = document.getElementsByTagName("form");

    const forms = document.getElementsByTagName("form");

    let values = {};
    const query = getQueryFromURL();
    // Set the query value in the values object
    if (query) {
      values["query"] = query;
    }

    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      const inputs = form.getElementsByTagName("input");
      const selects = form.getElementsByTagName("select");

      for (let s = 0; s < selects.length; s++) {
        const select = selects[s];
        const { name, value, type, checked } = select;
        values[name] = value;
      }
      for (let j = 0; j < inputs.length; j++) {
        const input = inputs[j];
        const { name, value, type, checked } = input;
        if (type === "checkbox") {
          if (checked) {
            if (values[name]) {
              const existingValues = values[name]
                .split(",")
                .filter((val) => val.trim() !== "");

              if (!existingValues.includes(value)) {
                existingValues.push(value);
              }

              values[name] = existingValues.join(",");
            } else {
              values[name] = value;
            }
          }
        } else if (type === "radio") {
          if (checked) {
            values[name] = value;
          }
        } else if (value !== "") {
          values[name] = value;
        }
      }
    }
    if (
      geometry !== null &&
      geometry !== "null" &&
      geometry !== "" &&
      geometry !== undefined
    ) {
      values["geometry"] = geometry;
    }
    setFormValues(values);

    const hasValues =
      Object.values(values).some((value) => value !== "") ||
      props.drawnFeatures;
    if (!hasValues) {
      setIsLoadingform(false);
      toast.error("Please fill in the form values.");

      return false;
    }
    for (let key in selectedOption) {
      if (selectedOption.hasOwnProperty(key)) {
        values[key] = selectedOption[key];
      }
    }
    for (let key in selectedOptionWithin) {
      if (selectedOptionWithin.hasOwnProperty(key)) {
        values[key] = selectedOptionWithin[key];
      }
    }
    for (let key in multSelectOptions) {
      if (multSelectOptions.hasOwnProperty(key)) {
        values[key] = multSelectOptions[key];
      }
    }

    values = { ...values, page: event.selected + 1 };
    let all_values = { ...values, ...all_url_params };
    all_values = { ...all_values, page: event.selected + 1 };
    http
      .post("/getsearchproperties", helpers.doObjToFormData(all_values))
      .then(({ data }) => {
        setIsLoadingform(false);
        renderMap(data.parcel_ids, values);
        const properties_list = JSON.stringify(data);
        localStorage.setItem("listingData", properties_list);
        localStorage.setItem("currentFilters", JSON.stringify(values));
        setSearchResultsData([]);
        setSearchResultsData(JSON.stringify(data));
        setTotalPropertiesRecords(Number(data.totalRecords));

        setShowSearchResults(true);
        ownertoggle(true);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PROPERTIES_CONTENT_FAILED,
          payload: error,
        });
      });
  };

  const handleSubmit = (
    event,
    formName1,
    sortValueFilter = null,
    searchPage = null,
    search_text_box = false,
    isPropertyFilterApplied = false
  ) => {
    event.preventDefault();
    if (formName1 === "more_filters") {
    }
    // Check if the filter applied is for property type
    if (propertyTypeExists === true) {
      isPropertyFilterApplied = true;
    }

    filterSearchSubmitData(
      formName1,
      sortValueFilter,
      searchPage,
      isPropertyFilterApplied ? "yes" : "no",
      search_text_box
    );
  };
  const filterSearchSubmitData = (
    formName1,
    sortValueFilter = null,
    searchPage = null,
    search_nearby = "",
    search_text_box = false
  ) => {
    let values = {};
    console.log(formName1, "formName1");
    if (formName1 == "search_field") {
      if (props.setDrawnFeatures) {
        // props.setDrawnFeatures(null);
      }

      localStorage.setItem("drawnFeatures", null);
    }
    if (formName1 == "building_and_lot") {
      const form = document.querySelector(`#${formName1}`);
      const selects = form.getElementsByTagName("select");

      for (let s = 0; s < selects.length; s++) {
        const select = selects[s];
        const { name, value } = select;
        values[name] = value;
      }
      const inputs = form.getElementsByTagName("input");
      for (let j = 0; j < inputs.length; j++) {
        const input = inputs[j];
        const { name, value, type, checked } = input;
        if (name === "arealotsf_from") {
          const numericValue = parseFloat(value);
          if (value !== "" && value !== null && value !== undefined) {
            if (
              !isNaN(numericValue) &&
              numericValue >= 100 &&
              numericValue <= 100000
            ) {
            } else {
              // Handle invalid input (for example, you can set it to an empty string)
              // Optionally, you can display an error message to the user
              toast.error(
                "Please enter a value between 100 and 100000 for Lot size From"
              );
              return;
            }
          }
        } else if (name === "arealotsf_until") {
          const numericValue = parseFloat(value);
          if (value !== "" && value !== null && value !== undefined) {
            if (
              !isNaN(numericValue) &&
              numericValue >= 100 &&
              numericValue <= 100000
            ) {
            } else {
              // Handle invalid input (for example, you can set it to an empty string)

              // Optionally, you can display an error message to the user
              toast.error(
                "Please enter a value between 100 and 100000 for Lot size Until"
              );
              return;
            }
          }
        }
      }
    }

    // setResultView("list");
    dispatch(toggleResultView("table"));
    // setIsLoadingform(true);
    setFormValues({});

    localStorage.setItem("setPreviousForm", formName1);
    const form = document.querySelector(`#${formName1}`);

    if (!form?.checkValidity()) {
      // Form is invalid, handle error
      return;
    }
    const forms = document.getElementsByTagName("form");

    const query = getQueryFromURL();
    // Set the query value in the values object
    if (query) {
      values["query"] = query;
    }

    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      const inputs = form.getElementsByTagName("input");
      const selects = form.getElementsByTagName("select");

      for (let s = 0; s < selects.length; s++) {
        const select = selects[s];
        const { name, value, type, checked } = select;
        values[name] = value;
      }
      for (let j = 0; j < inputs.length; j++) {
        const input = inputs[j];
        const { name, value, type, checked } = input;
        if (type === "checkbox") {
          if (checked) {
            if (values[name]) {
              const existingValues = values[name]
                .split(",")
                .filter((val) => val.trim() !== "");

              if (!existingValues.includes(value)) {
                existingValues.push(value);
              }

              values[name] = existingValues.join(",");
            } else {
              values[name] = value;
            }
          }
        } else if (type === "radio") {
          if (checked) {
            values[name] = value;
          }
        } else if (value !== "") {
          values[name] = value;
        }
      }
    }
    if (formName1 === "property_type") {
      const form = document.querySelector(`#${formName1}`);
      const inputs = form.getElementsByTagName("input");
      let property_types_arr = [];
      for (let j = 0; j < inputs.length; j++) {
        const input = inputs[j];

        const { name, value, type, checked } = input;
        if (checked) {
          property_types_arr.push(value);
        } else {
          property_types_arr = property_types_arr?.filter(
            (item) => item !== value
          );
        }
        console.log("property_type_arrr_inside_pro", property_types_arr);
      }
      values["property_type"] = property_types_arr.join(",");
    }
    if (formName1 === "more_filters") {
      const form = document.querySelector(`#${formName1}`);
      const inputs = form.getElementsByTagName("input");
      let property_types_arr = [];
      for (let j = 0; j < inputs.length; j++) {
        const input = inputs[j];

        const { name, value, type, checked } = input;
        if (name === "property_type") {
          if (checked) {
            property_types_arr.push(value);
          } else {
            property_types_arr = property_types_arr?.filter(
              (item) => item !== value
            );
          }
        }
        console.log("property_type_arrr_inside_more", property_types_arr);
      }
      values["property_type"] = property_types_arr.join(",");
    }

    setFormValues(values);
    const hasValues =
      Object.values(values).some((value) => value !== "") ||
      props.drawnFeatures;
    if (!hasValues) {
      setIsLoadingform(false);
      toast.error("Please fill in the form values.");

      return false;
    }
    for (let key in selectedOption) {
      if (selectedOption.hasOwnProperty(key)) {
        values[key] = selectedOption[key];
      }
    }
    for (let key in selectedOptionWithin) {
      if (selectedOptionWithin.hasOwnProperty(key)) {
        values[key] = selectedOptionWithin[key];
      }
    }
    for (let key in multSelectOptions) {
      if (multSelectOptions.hasOwnProperty(key)) {
        values[key] = multSelectOptions[key];
      }
    }
    console.log("values before search api req", values);
    // console.log(values);return;
    // values=updateObjectBasedOnCondition(values, formData);
    searchApiReq(
      values,
      sortValueFilter,
      searchPage,
      search_nearby,
      search_text_box
    );
  };
  function updateObjectBasedOnCondition(obj1, obj2) {
    // Check if the second object has an empty `property_type` array
    if (
      obj2.property_type &&
      Array.isArray(obj2.property_type) &&
      obj2.property_type.length === 0
    ) {
      // Remove `property_type` from the first object
      delete obj1.property_type;
    }
    const url = new URL(window.location.href);
    url.searchParams.delete("property_type");
    window.history.replaceState(null, "", url.toString());
    // Return the updated first object
    return obj1;
  }

  const updateParams = (newParams, search_nearby = "") => {
    console.log("values inside updateParams", newParams);
    if (newParams?.mortgage_amount) {
      if (Array.isArray(newParams?.mortgage_amount)) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          mortgage_amount: newParams?.mortgage_amount,
        }));
      }
    }
    if (newParams?.sales_price) {
      if (Array.isArray(newParams?.sales_price)) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          sales_price: newParams?.sales_price,
        }));
      }
    }
    if (newParams?.portfolio_assessed_value) {
      if (Array.isArray(newParams?.portfolio_assessed_value)) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          portfolio_assessed_value: newParams?.portfolio_assessed_value,
        }));
      }
    }
    if (newParams?.sales_price_sf) {
      if (Array.isArray(newParams?.sales_price_sf)) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          sales_price_sf: newParams?.sales_price_sf,
        }));
      }
    }
    setSearchQueryValues(newParams);
    const updatedParams = new URLSearchParams(queryParams.entries());
    if (search_nearby == "no") {
      updatedParams.delete("search_nearby");
    }

    Object.entries(newParams).forEach(([key, value]) => {
      updatedParams.set(key, value);
    });
    if (!newParams?.property_type) {
      updatedParams.delete("property_type");
    }
    if (!newParams?.min_sales_price_sf) {
      updatedParams.delete("min_sales_price_sf");
    }
    if (!newParams?.max_sales_price_sf) {
      updatedParams.delete("max_sales_price_sf");
    }
    if (!newParams?.max_sales_price_acr) {
      updatedParams.delete("max_sales_price_acr");
    }
    if (!newParams?.min_sales_price_acr) {
      updatedParams.delete("min_sales_price_acr");
    }
    if (!newParams?.min_sales_price) {
      updatedParams.delete("min_sales_price");
    }
    if (!newParams?.max_sales_price) {
      updatedParams.delete("max_sales_price");
    }
    window.history.replaceState(null, "", `?${updatedParams.toString()}`);
  };
  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const searchApiReq = (
    values,
    sortValueFilter = null,
    searchPage = null,
    search_nearby = null,
    search_text_box = false
  ) => {
    const geometry_polygon = getUrlParameter("geometry");
    let geometry = props.drawnFeatures || geometry_polygon || null;

    if (search_text_box) {
      geometry = null;
    }
    values = {
      ...values,
      page:
        searchPage !== null && searchPage !== undefined && searchPage !== ""
          ? searchPage
          : page,
      geometry: geometry,
      sort:
        sortValueFilter !== null
          ? sortValueFilter
          : values?.sort
          ? values?.sort
          : sortValue,
      labels_includes_arr: includeLabels,
      label_exclude: excludeLabels,
    };
    if (includeLabels) {
      values["labels_includes_arr"] = includeLabels;
    }
    if (excludeLabels) {
      values["label_exclude"] = excludeLabels;
    }
    if (search_nearby !== null && search_nearby !== "no") {
      values["search_nearby"] = search_nearby;
    }
    if (values?.mortgage_amount) {
      if (Array.isArray(values?.mortgage_amount)) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          mortgage_amount: values?.mortgage_amount,
        }));
      }
    }
    if (values?.sales_price) {
      if (Array.isArray(values?.sales_price)) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          sales_price: values?.sales_price,
        }));
      }
    }
    if (values?.portfolio_assessed_value) {
      if (Array.isArray(values?.portfolio_assessed_value)) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          portfolio_assessed_value: values?.portfolio_assessed_value,
        }));
      }
    }
    if (values?.sales_price_sf) {
      if (Array.isArray(values?.sales_price_sf)) {
        setMultiSelectOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          sales_price_sf: values?.sales_price_sf,
        }));
      }
    }
    if (location?.pathname !== "/search-property") {
      const queryString = new URLSearchParams(values).toString();

      const url = `/search-property?${queryString}`;
      window.location.href = url;
    }
    // console.log(values);return;
    updateParams(values, search_nearby);
    setIsLoadingform(true);

    localStorage.setItem("drawnFeatures", props.drawnFeatures || null);
    http
      .post("/getsearchproperties", helpers.doObjToFormData(values))
      .then(({ data }) => {
        setIsLoadingform(false);
        if (data.status == 2) {
          toast.error(data.msg);
          return false;
        }
        setShowSearchResults(true);
        // renderMap(data.parcel_ids, values);
        const properties_list = JSON.stringify(data);
        localStorage.setItem("listingData", properties_list);
        localStorage.setItem("currentFilters", JSON.stringify(values));
        setSearchResultsData([]);
        setSearchResultsData(JSON.stringify(data));
        setTotalPropertiesRecords(Number(data.totalRecords));

        // setShowMap(false);

        setShowSearchResults(true);
        Maptoggle(false);
        ownertoggle(true);

        if (!window.location.pathname.includes("search-property")) {
          const newPath = "/search-property";
          // window.history.pushState(null, '', newPath);
          navigate("/search-property");
        }
      })
      .catch((error) => {
        // localStorage.clear();
        // window.location.reload();
        dispatch({
          type: FETCH_PROPERTIES_CONTENT_FAILED,
          payload: error,
        });
      });
  };

  useEffect(() => {
    if (
      props.drawnFeatures &&
      localStorage.getItem("drawnFeatures") !== props.drawnFeatures
    ) {
      filterSearchSubmitData("search_field");
    }
  }, [props.drawnFeatures]);

  // ...

  // useEffect(() => {
  //   const query = getQueryFromURL();

  //   if (query && !submitted) {
  //     const searchInput = document.getElementsByName("serch")[0];
  //     if (searchInput) {
  //       searchInput.value = query; // Assign query value to the "search" input field
  //     }
  //     const mockEvent = {
  //       preventDefault: () => { },
  //       target: {
  //         id: "search_field",
  //       },
  //     };

  //     handleSubmit(mockEvent, "search_field");
  //     setSubmitted(true);
  //     const url = new URL(window.location);
  //     url.searchParams.delete("query");
  //     window.history.pushState({}, "", url);
  //   }
  // }, []);

  const handleOutsideClick1 = (e) => {
    if (!popupRef1.current.contains(e.target)) {
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick1);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick1);
    };
  }, []);

  // useEffect(() => {
  //   if (search_p !== "" && localStorage.getItem("search-nearby")) {
  //     localStorage.removeItem("search-nearby");
  //     const mockEvent = {
  //       preventDefault: () => { },
  //       target: {
  //         id: "search_field",
  //       },
  //     };
  //     // handleSubmit(mockEvent, "search_field");
  //     // if (searchButtonRef.current)
  //     // searchButtonRef.current.click();
  //     handleSubmit(mockEvent, "search_field");
  //     setSubmitted(true);
  //   }
  // }, []);

  useEffect(() => {
    //setShowSearchResults(true);
    // Assign values back to the form whenever showSearchResults is true
    if (showSearchResults) {
      setPropertyType(false);
      setRecommended(false);
      setMyProperties(false);
      setMoreFilters(false);
      setPropertySize(false);

      for (const name in formValues) {
        const value = formValues[name];
        const inputs = document.getElementsByName(name);

        for (let i = 0; i < inputs.length; i++) {
          const input = inputs[i];

          if (input.type === "checkbox") {
            input.checked = value.includes(input.value);
          } else if (input.type === "radio") {
            input.checked = input.value === value;
          } else {
            input.value = value;
          }
        }
      }
    }
  }, [formValues, showSearchResults]);

  useEffect(() => {}, [showSearchResults]);

  useEffect(() => {
    http
      .post("getFilterCounts", "")
      .then(({ data }) => {
        setPropertyCatsCounts(data?.counts);
        // setCommercialCount(data.counts.Commercial.toLocaleString());
        // setAGRICULTURECOunt(
        //   data.counts["AGRICULTURE / FARMING"].toLocaleString()
        // );
        // setINDUSTRIALCount(data.counts["Industrial"].toLocaleString());
        // setResidentialCount(data.counts["Residential"].toLocaleString());
        // setPUBLICount(data.counts["PUBLIC WORKS"].toLocaleString());
        // setOTHERCount(data.counts["OTHER / UNKNOWN"].toLocaleString());
        // setVACANTCOUNT(data.counts["VACANT LAND"].toLocaleString());
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  // Render Map base of Parcel Id's

  const renderMap = (parcel_ids, filtervalues) => {};

  // setPropertyType(false);
  // setRecommended(false);
  // setMyProperties(false);
  // setMoreFilters(false);
  // setPropertySize(false);

  const propertyTypeDrop = useRef();
  const recommendedDrop = useRef();
  const myPropertiesDrop = useRef();
  const propertySizeDrop = useRef();
  const moreFiltersDrop = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        !propertyTypeDrop?.current?.contains(e.target) &&
        !myPropertiesDrop?.current?.contains(e.target) &&
        !propertySizeDrop?.current?.contains(e.target) &&
        !moreFiltersDrop?.current?.contains(e.target) &&
        !recommendedDrop?.current?.contains(e.target)
      ) {
        setPropertyType(false);
        setRecommended(false);
        setMyProperties(false);
        setMoreFilters(false);
        setPropertySize(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  // useEffect(() => {
  // }, [searchResultsData]);
  const yearOptions = helpers.getYearOptions();
  const lotSizeRange = helpers.getNumbersRange();
  let property_type = searchQueryValues?.property_type;

  if (property_type) {
    if (property_type?.includes(",")) {
      property_type = property_type?.split(",");
    }
  }
  useEffect(() => {
    if (propertyType || propertySize || recommended || myProperties) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }

    // Cleanup on unmount
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, [propertyType, propertySize, recommended, myProperties]);

  return (
    <>
      {isLoadingform ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />

          <div
            ref={popupRef1}
            className={
              hideSearch ? "filter_sec hide_filter_sec_new" : "filter_sec"
            }
          >
            <div className="contain-fluid">
              <div className="filter_nav">
                <div
                  className={
                    hideSearch ? "colL show_search_btn_active" : "colL"
                  }
                >
                  <form id="search_field">
                    <div className="search_filter_first cell_search_flex_field">
                      <div className="seach_field after_search">
                        <input
                          type="text"
                          name="search_query"
                          className="input"
                          onKeyDown={handleKeyDown}
                          placeholder={searchPlaceHolder}
                          id="search_field_id"
                          defaultValue={searchQueryValues?.search_query}
                          ref={searchInputRef}
                        />
                        <select
                          name="search_type"
                          className="input"
                          onChange={hanldeSearchSelectFieldSubmit}
                        >
                          <option
                            value="address"
                            selected={
                              searchQueryValues?.search_type === "address"
                                ? true
                                : false
                            }
                          >
                            Address
                          </option>
                          <option
                            value="city_state"
                            selected={
                              searchQueryValues?.search_type === "city_state"
                                ? true
                                : false
                            }
                          >
                            City, State
                          </option>
                          <option
                            value="zipcode"
                            selected={
                              searchQueryValues?.search_type === "zipcode"
                                ? true
                                : false
                            }
                          >
                            Zipcode
                          </option>
                          <option
                            value="owner_name"
                            selected={
                              searchQueryValues?.search_type === "owner_name"
                                ? true
                                : false
                            }
                          >
                            Property Owner
                          </option>
                        </select>
                        <button
                          type="button"
                          ref={searchButtonRef}
                          onClick={hanldeSearchFieldSubmit}
                        >
                          <img src="/images/search_green.svg" alt="" />
                        </button>
                      </div>
                      <button
                        className="hide_cell_filter"
                        onClick={ToggleHideSearch}
                        type="button"
                      >
                        <img src="/images/eye_circle_hide.svg" alt="" />
                      </button>
                    </div>
                  </form>
                  <div className="outer_oth">
                    <div className="oth_filter">
                      <div className="drop_filter" ref={propertyTypeDrop}>
                        <div
                          className="filter_btn site_btn blank yellow_blank"
                          onClick={TogglePropertyType}
                        >
                          Property type
                        </div>
                        <div
                          className={
                            propertyType
                              ? "drop_cnt active filter_counts"
                              : "drop_cnt filter_counts"
                          }
                        >
                          <div className="head_filter">
                            <p className="dark_text">Popular categories</p>
                            {/* <p className="dim_text">
                              <a href="">See all property types</a>
                            </p> */}
                          </div>
                          <form
                            id="property_type"
                            onSubmit={(event) =>
                              handleSubmit(event, "property_type")
                            }
                          >
                            <div className="form-row row">
                              {Object.entries(propertyCatsCounts).map(
                                ([key, count]) => (
                                  <div className="col-md-6" key={key}>
                                    <div className="lbl_btn">
                                      <input
                                        type="checkbox"
                                        name="property_type"
                                        id={key}
                                        value={key}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            "property_type"
                                          )
                                        }
                                        defaultChecked={
                                          Array.isArray(property_type) &&
                                          property_type.length > 0
                                            ? property_type.some(
                                                (item) => item === key
                                              )
                                            : // If property_type is a single value, check for exact match
                                              property_type === key
                                        }
                                      />
                                      <label htmlFor={key}>
                                        <span>{key || "Unknown"}</span>
                                        <span>{count}</span>
                                      </label>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>

                            <div className="btn_blk text-right property_type_btn">
                              <button
                                className="site_btn blank yellow_blank"
                                onClick={(event) =>
                                  handleClearForm(event, "property_type")
                                }
                              >
                                Clear
                              </button>
                              <a
                                href="javscript:viod(0)"
                                onClick={(event) =>
                                  handleSubmit(event, "property_type")
                                }
                                className="site_btn"
                              >
                                Apply
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="drop_filter" ref={propertySizeDrop}>
                        <div
                          className="filter_btn site_btn blank yellow_blank"
                          onClick={TogglePropertySize}
                        >
                          Size
                        </div>
                        <div
                          className={
                            propertySize
                              ? "drop_cnt active fix_height_filter"
                              : "drop_cnt fix_height_filter"
                          }
                        >
                          <form
                            id="property_size"
                            onSubmit={(event) =>
                              handleSubmit(event, "property_size")
                            }
                            className="half_flex_form"
                          >
                            <div className="form_blk">
                              <h6>Total units</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="min_unites"
                                    id="min_unites"
                                    className="input"
                                    placeholder="Min Units"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_size")
                                    }
                                    defaultValue={searchQueryValues?.min_unites}
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="max_unites"
                                    id="max_unites"
                                    className="input"
                                    placeholder="Max Units"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_size")
                                    }
                                    defaultValue={searchQueryValues?.max_unites}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form_blk">
                              <h6>Building area (SF)</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="min_sf"
                                    id="min_sf"
                                    className="input"
                                    placeholder="Min (SF)"
                                    onChange={(event) =>
                                      handleInputChange(event, "min_sf")
                                    }
                                    defaultValue={searchQueryValues?.min_sf}
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="max_sf"
                                    id="max_sf"
                                    className="input"
                                    placeholder="Max (SF)"
                                    onChange={(event) =>
                                      handleInputChange(event, "max_sf")
                                    }
                                    defaultValue={searchQueryValues?.max_sf}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form_blk">
                              <h6>Lot Size</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="min_lotsize"
                                    id="min_lotsize"
                                    className="input"
                                    placeholder="Min Value"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_size")
                                    }
                                    defaultValue={
                                      searchQueryValues?.min_lotsize
                                    }
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="max_lotsize"
                                    id="max_lotsize"
                                    className="input"
                                    placeholder="Max Value"
                                    onChange={(event) =>
                                      handleInputChange(event, "property_size")
                                    }
                                    defaultValue={
                                      searchQueryValues?.max_lotsize
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="btn_blk text-right">
                              <button
                                className="site_btn blank yellow_blank"
                                onClick={(event) =>
                                  handleClearForm(event, "property_size")
                                }
                              >
                                Clear
                              </button>
                              <button type="submit" className="site_btn">
                                Apply
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="drop_filter" ref={recommendedDrop}>
                        <div
                          className="filter_btn site_btn blank yellow_blank"
                          onClick={ToggleRecommended}
                        >
                          Recommended
                        </div>
                        <div
                          className={
                            recommended
                              ? "drop_cnt wide_sm active fix_height_filter"
                              : "drop_cnt wide_sm fix_height_filter"
                          }
                        >
                          <form
                            id="recommended"
                            onSubmit={(event) =>
                              handleSubmit(event, "recommended")
                            }
                            className="half_flex_form"
                          >
                            <div className="form_blk">
                              <h6>Year built</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="year_build"
                                    id="year_build"
                                    className="input"
                                    placeholder="Year built From"
                                    onChange={(event) =>
                                      handleInputChange(event, "recommended")
                                    }
                                    defaultValue={searchQueryValues?.year_build}
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="year_build_until"
                                    id="year_build_until"
                                    className="input"
                                    placeholder="Year built Until"
                                    onChange={(event) =>
                                      handleInputChange(event, "recommended")
                                    }
                                    defaultValue={
                                      searchQueryValues?.year_build_until
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form_blk">
                              <h6>Properties in portfolio</h6>
                              <div className="half_flex">
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="properties_in_portfolio_min"
                                    id="properties_in_portfolio_min"
                                    className="input"
                                    placeholder="Min"
                                    onChange={(event) =>
                                      handleInputChange(event, "recommended")
                                    }
                                    defaultValue={
                                      searchQueryValues?.properties_in_portfolio_min
                                    }
                                  />
                                </div>
                                <span></span>
                                <div className="half_field">
                                  <input
                                    type="text"
                                    name="properties_in_portfolio_max"
                                    id="properties_in_portfolio_max"
                                    className="input"
                                    placeholder="Max"
                                    onChange={(event) =>
                                      handleInputChange(event, "recommended")
                                    }
                                    defaultValue={
                                      searchQueryValues?.properties_in_portfolio_max
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form_blk margin-top-neg">
                              <div className="lbl_btn">
                                <input
                                  type="checkbox"
                                  name="occupaid"
                                  id="occupaid"
                                  value="occupaid"
                                  onChange={(event) =>
                                    handleInputChange(event, "recommended")
                                  }
                                  defaultChecked={
                                    searchQueryValues?.occupaid ? true : false
                                  }
                                />

                                <label
                                  htmlFor="occupaid"
                                  className="dim_dark_text f_s_15"
                                >
                                  Owner Occupied Property
                                </label>
                              </div>
                            </div>

                            <div className="form_blk margin-top-neg">
                              <div className="lbl_btn">
                                <input
                                  type="checkbox"
                                  name="recommended_owner_property_sq_ft"
                                  id="recommended_owner_property_sq_ft"
                                  value="recommended_owner_property_sq_ft"
                                  onChange={(event) =>
                                    handleInputChange(
                                      event,
                                      "recommended_owner_property_sq_ft"
                                    )
                                  }
                                  defaultChecked={
                                    searchQueryValues?.recommended_owner_property_sq_ft
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  htmlFor="recommended_owner_property_sq_ft"
                                  className="dim_dark_text f_s_15"
                                >
                                  Property over 5,000 square feet
                                </label>
                              </div>
                            </div>
                            <div className="form_blk margin-top-neg">
                              <div className="lbl_btn">
                                <input
                                  type="checkbox"
                                  name="recommended_owner_portfolio"
                                  id="recommended_owner_portfolio"
                                  value="recommended_owner_portfolio"
                                  onChange={(event) =>
                                    handleInputChange(
                                      event,
                                      "recommended_owner_portfolio"
                                    )
                                  }
                                  defaultChecked={
                                    searchQueryValues?.recommended_owner_portfolio
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  htmlFor="recommended_owner_portfolio"
                                  className="dim_dark_text f_s_15"
                                >
                                  Portfolio with over 5 properties
                                </label>
                              </div>
                            </div>

                            <div className="btn_blk text-right">
                              <button
                                className="site_btn blank yellow_blank"
                                onClick={(event) =>
                                  handleClearForm(event, "recommended")
                                }
                              >
                                Clear
                              </button>
                              <a
                                href="javscript:viod(0)"
                                onClick={(event) =>
                                  handleSubmit(event, "property_size")
                                }
                                className="site_btn"
                              >
                                Apply
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="drop_filter" ref={myPropertiesDrop}>
                        <div
                          className="filter_btn site_btn blank yellow_blank"
                          onClick={ToggleMyProperties}
                        >
                          My properties
                        </div>
                        <div
                          className={
                            myProperties
                              ? "drop_cnt wide_sm right_drop_cnt active"
                              : "drop_cnt wide_sm right_drop_cnt"
                          }
                        >
                          <form
                            id="my_properties"
                            onSubmit={(event) =>
                              handleSubmit(event, "my_properties")
                            }
                          >
                            <div className="form_blk">
                              <h6 className="f_size_16">Exported properties</h6>
                              <div className="boolean_flex">
                                <div className="lbl_btn">
                                  <input
                                    type="radio"
                                    name="exported_properties"
                                    id="include"
                                    value="include"
                                    defaultChecked={
                                      selectedOption["include"] === "include"
                                    }
                                    onChange={(event) =>
                                      handleRadioChange(
                                        event,
                                        "exported_properties",
                                        "include"
                                      )
                                    }
                                    className="hidden"
                                  />
                                  <label
                                    htmlFor="include"
                                    className="site_btn blank yellow_blank main_color"
                                    style={{
                                      backgroundColor:
                                        selectedOption[
                                          "exported_properties"
                                        ] === "include"
                                          ? "#67773F"
                                          : "",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color:
                                          selectedOption[
                                            "exported_properties"
                                          ] === "include"
                                            ? "white"
                                            : "black",
                                      }}
                                    >
                                      Include
                                    </span>
                                  </label>
                                </div>
                                <div className="lbl_btn">
                                  <input
                                    type="radio"
                                    name="exported_properties"
                                    id="exclude"
                                    value="exclude"
                                    checked={
                                      selectedOption["include"] === "exclude"
                                    }
                                    onChange={(event) =>
                                      handleRadioChange(
                                        event,
                                        "exported_properties",
                                        "exclude"
                                      )
                                    }
                                    className="hidden"
                                  />
                                  <label
                                    htmlFor="exclude"
                                    className="site_btn blank yellow_blank main_color"
                                    style={{
                                      backgroundColor:
                                        selectedOption[
                                          "exported_properties"
                                        ] === "exclude"
                                          ? "#67773F"
                                          : "",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color:
                                          selectedOption[
                                            "exported_properties"
                                          ] === "exclude"
                                            ? "white"
                                            : "black",
                                      }}
                                    >
                                      Exclude
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form_blk margin-top-neg">
                              <h6 className="f_s_14 f_dim_light">Labels</h6>
                              <input
                                type="text"
                                className="input"
                                id="labels_text"
                                name="labels_text"
                                onChange={handleLabelTextChange}
                                placeholder="Search for label"
                              />
                            </div>
                            <div className="form_blk margin-top-neg lbl_table_search">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Labels</th>
                                    <th>Includes</th>
                                    <th>Exclude</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userLabels.map((label) => (
                                    <tr>
                                      <td
                                        className="lbl_dot blue_dot"
                                        style={{
                                          backgroundColor: label.label_color,
                                        }}
                                      >
                                        {label.label_name}
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="labels_includes_arr"
                                          defaultValue={label.label_name}
                                          onChange={handleLabelIncludeChange}
                                          defaultChecked={
                                            includeLabels?.includes(
                                              label?.label_name
                                            )
                                              ? true
                                              : false
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="labels_excludes_arr"
                                          defaultValue={label.label_name}
                                          onChange={handleLabelExcludeChange}
                                          defaultChecked={
                                            excludeLabels?.includes(
                                              label?.label_name
                                            )
                                              ? true
                                              : false
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="btn_blk text-right">
                              <button
                                className="site_btn blank yellow_blank"
                                onClick={(event) =>
                                  handleClearForm(event, "my_properties")
                                }
                              >
                                Clear
                              </button>
                              <button type="submit" className="site_btn">
                                Apply
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        className="drop_filter mobile_filter_advance"
                        ref={moreFiltersDrop}
                      >
                        <button
                          className="site_btn yellow_blank blank"
                          onClick={ToggleMoreFilters}
                        >
                          <img src="/images/plus_icon_dark.svg" alt="" />
                          <img
                            src="/images/ad_filter.svg"
                            alt=""
                            className="show_cell_advance_icon"
                          />{" "}
                          <span>More filters</span>
                        </button>
                        <div
                          className={
                            moreFilters
                              ? "drop_cnt wide_lg active"
                              : "drop_cnt wide_lg"
                          }
                        >
                          <div className="more_filter_drop tabs_filter">
                            <div className="sub_filter">
                              <div className="tabs_main">
                                <div className="react-tabs">
                                  <ul
                                    className="react-tabs__tab-list"
                                    role="tablist"
                                  >
                                    {tabs.map((tab, index) => (
                                      <li
                                        key={index}
                                        className={`react-tabs__tab ${
                                          selectedTab === index
                                            ? "react-tabs__tab--selected"
                                            : ""
                                        }`}
                                        role="tab"
                                        aria-selected={selectedTab === index}
                                        tabIndex={
                                          selectedTab === index ? 0 : -1
                                        }
                                        onClick={() => setSelectedTab(index)}
                                      >
                                        <button className="site_btn blank">
                                          {tab}
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <form id="more_filters">
                                  <div
                                    class="react-tabs"
                                    data-rttabs="true"
                                    style={{
                                      display:
                                        selectedTab === 0 ? "block" : "none",
                                    }}
                                  >
                                    <div class="react-tabs__tab-panel react-tabs__tab-panel--selected">
                                      <div className="check_flex flex">
                                        <div className="col">
                                          <div className="form-row row">
                                            {Object.entries(
                                              propertyCatsCounts
                                            ).map(([key, count]) => (
                                              <div
                                                className="col-md-6"
                                                key={key}
                                              >
                                                <div className="lbl_btn">
                                                  <input
                                                    type="checkbox"
                                                    name="property_type"
                                                    id={key}
                                                    value={key}
                                                    onChange={(event) =>
                                                      handleInputChange(
                                                        event,
                                                        "property_type"
                                                      )
                                                    }
                                                    defaultChecked={
                                                      Array.isArray(
                                                        property_type
                                                      ) &&
                                                      property_type.length > 0
                                                        ? property_type.some(
                                                            (item) =>
                                                              item === key
                                                          )
                                                        : // If property_type is a single value, check for exact match
                                                          property_type === key
                                                    }
                                                  />
                                                  <label htmlFor={key}>
                                                    <span>
                                                      {key || "Unknown"}
                                                    </span>
                                                    <span>{count}</span>
                                                  </label>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="react-tabs"
                                    data-rttabs="true"
                                    style={{
                                      display:
                                        selectedTab === 1 ? "block" : "none",
                                    }}
                                  >
                                    <div class="react-tabs__tab-panel react-tabs__tab-panel--selected">
                                      <div className="mix_half_flex flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <h6>Year built</h6>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <select
                                                  name="yearbuilt_from"
                                                  id="yearbuilt_from"
                                                  className="input"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                >
                                                  <option value="">From</option>
                                                  {yearOptions?.map(
                                                    (year, index) => {
                                                      return (
                                                        <option
                                                          value={year?.value}
                                                          key={index}
                                                          selected={
                                                            parseInt(
                                                              searchQueryValues?.yearbuilt_from
                                                            ) ===
                                                            parseInt(
                                                              year?.value
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                        >
                                                          {year?.label}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <select
                                                  name="yearbuilt_until"
                                                  id="yearbuilt_until"
                                                  className="input"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    until
                                                  </option>
                                                  {yearOptions?.map(
                                                    (year, index) => {
                                                      return (
                                                        <option
                                                          value={year?.value}
                                                          key={index}
                                                          selected={
                                                            parseInt(
                                                              searchQueryValues?.yearbuilt_until
                                                            ) ===
                                                            parseInt(
                                                              year?.value
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                        >
                                                          {year?.label}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <h6>Lot size (SF)</h6>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="arealotsf_from"
                                                  id="arealotsf_from"
                                                  className="input"
                                                  placeholder="Min (SF)"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.arealotsf_from
                                                  }
                                                />
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="arealotsf_until"
                                                  id="arealotsf_until"
                                                  className="input"
                                                  placeholder="Max (SF)"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.arealotsf_until
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col half_flex_form nested_half_flex">
                                          <div className="form_blk">
                                            <h6>Zoning</h6>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="zonedcodelocal"
                                                  className="input"
                                                  placeholder="Example: C2, I3, R5"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "building_and_lot"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.zonedcodelocal
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="react-tabs"
                                    data-rttabs="true"
                                    style={{
                                      display:
                                        selectedTab === 2 ? "block" : "none",
                                    }}
                                  >
                                    <div class="react-tabs__tab-panel react-tabs__tab-panel--selected">
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Owner</h6>
                                            </div>
                                            <input
                                              type="text"
                                              className="input"
                                              name="owner_name"
                                              placeholder="Person or Company name"
                                              defaultValue={
                                                searchQueryValues?.owner_name
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Properties in portfolio</h6>
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="properties_in_portfolio_min"
                                                  id="properties_in_portfolio_min"
                                                  className="input"
                                                  placeholder="Min"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "recommended"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.properties_in_portfolio_min
                                                  }
                                                />
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="properties_in_portfolio_max"
                                                  id="properties_in_portfolio_max"
                                                  className="input"
                                                  placeholder="Max"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "recommended"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.properties_in_portfolio_max
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Owner type</h6>
                                            </div>
                                            <div className="boolean_flex">
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="owner_type"
                                                  value="Company"
                                                  checked={
                                                    selectedOption[
                                                      "owner_type"
                                                    ] === "Company"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "owner_type",
                                                      "Company"
                                                    )
                                                  }
                                                  id="Company"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="Company"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "owner_type"
                                                      ] === "Company"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "owner_type"
                                                        ] === "Company"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Company
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="owner_type"
                                                  value="Individual"
                                                  checked={
                                                    selectedOption[
                                                      "owner_type"
                                                    ] === "Individual"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "owner_type",
                                                      "Individual"
                                                    )
                                                  }
                                                  id="Person"
                                                  className="hidden"
                                                />
                                                <label
                                                  for="Person"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "owner_type"
                                                      ] === "Individual"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "owner_type"
                                                        ] === "Individual"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Individual
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Owner Occupied</h6>
                                            </div>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="Owner_occupied"
                                                value="Owner-Occupaid Property"
                                                checked={
                                                  selectedOption[
                                                    "owner_type"
                                                  ] ===
                                                  "Owner-Occupaid Property"
                                                }
                                                onChange={(event) =>
                                                  handleRadioChange(
                                                    event,
                                                    "owner_type",
                                                    "Owner-Occupaid Property"
                                                  )
                                                }
                                                id="Person"
                                              />
                                              <label
                                                for="Person"
                                                style={{
                                                  backgroundColor:
                                                    selectedOption[
                                                      "owner_type"
                                                    ] ===
                                                    "Owner-Occupaid Property"
                                                      ? "#67773F"
                                                      : "",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color:
                                                      selectedOption[
                                                        "owner_type"
                                                      ] ===
                                                      "Owner-Occupaid Property"
                                                        ? "white"
                                                        : "black",
                                                  }}
                                                >
                                                  {" "}
                                                  Owner Occupied Property
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col"></div>

                                        <div className="col half_flex_form">
                                          <div className="form_blk range_slide">
                                            <div className="info_lbl_lbl">
                                              <h6>Portfolio assessed value</h6>
                                            </div>
                                            <MultiRangeSlider
                                              name="portfolio_assessed_value"
                                              defaultMin={
                                                multSelectOptions
                                                  ?.portfolio_assessed_value
                                                  ?.length > 0
                                                  ? multSelectOptions
                                                      ?.portfolio_assessed_value[0]
                                                  : 0
                                              }
                                              defaultMax={
                                                multSelectOptions
                                                  ?.portfolio_assessed_value
                                                  ?.length > 0
                                                  ? multSelectOptions
                                                      ?.portfolio_assessed_value[1]
                                                  : 100000000
                                              }
                                              min={0}
                                              max={100000000}
                                              onChange={({ min, max }) =>
                                                handleMultiSelectChange(
                                                  "portfolio_assessed_value",
                                                  min,
                                                  max
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="br"></div>
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Reported owner</h6>
                                              <ReactTooltip
                                                id="reported-owner"
                                                place="bottom"
                                                content="Current owner of the property"
                                              />
                                              <div
                                                className="info_span"
                                                data-tooltip-id="reported-owner"
                                              >
                                                <span className="ic_on">
                                                  <img
                                                    src="/images/info-circle.svg"
                                                    alt=""
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            <input
                                              type="text"
                                              className="input"
                                              name="reported_owner"
                                              placeholder="Reported owner"
                                              defaultValue={
                                                searchQueryValues?.reported_owner
                                              }
                                            />
                                          </div>
                                          <div className="br"></div>
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>
                                                Reported owner mailing address
                                              </h6>
                                            </div>
                                            <input
                                              type="text"
                                              className="input"
                                              name="reported_owner_address"
                                              placeholder="Mailing address"
                                              defaultValue={
                                                searchQueryValues?.reported_owner_address
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="react-tabs"
                                    data-rttabs="true"
                                    style={{
                                      display:
                                        selectedTab === 3 ? "block" : "none",
                                    }}
                                  >
                                    <div class="react-tabs__tab-panel react-tabs__tab-panel--selected">
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk padding_right_span">
                                            <div className="info_lbl_lbl">
                                              <h6>Sales date</h6>
                                              <div className="span_type_lbl">
                                                <span
                                                  className={
                                                    selectedOptionWithin[
                                                      "sale_date_within"
                                                    ] === "yes"
                                                      ? "btn_sm_span fil_btn"
                                                      : "btn_sm_span"
                                                  }
                                                  onClick={(event) =>
                                                    handleRadioWithinChange(
                                                      event,
                                                      "sale_date_within",
                                                      "yes"
                                                    )
                                                  }
                                                >
                                                  Within
                                                </span>
                                                <span
                                                  className={
                                                    selectedOptionWithin[
                                                      "sale_date_within"
                                                    ] === "no"
                                                      ? "btn_sm_span fil_btn"
                                                      : "btn_sm_span"
                                                  }
                                                  onClick={(event) =>
                                                    handleRadioWithinChange(
                                                      event,
                                                      "sale_date_within",
                                                      "no"
                                                    )
                                                  }
                                                >
                                                  Not within
                                                </span>
                                              </div>
                                            </div>
                                            <div className="boolean_flex">
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  id="90_days"
                                                  value="90 days ago"
                                                  checked={
                                                    selectedOption[
                                                      "sale_date"
                                                    ] === "90 days ago"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "90 days ago"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="90_days"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "90 days ago"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "90 days ago"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 90 days
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  value="past year"
                                                  id="past_year"
                                                  checked={
                                                    selectedOption[
                                                      "sale_date"
                                                    ] === "past year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past year"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="past_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  value="past 2 years"
                                                  id="2_year"
                                                  checked={
                                                    selectedOption[
                                                      "sale_date"
                                                    ] === "past 2 years"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past 2 years"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="2_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past 2 years"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past 2 years"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 2 year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  id="5_year"
                                                  value="past 5 years"
                                                  checked={
                                                    selectedOption[
                                                      "sale_date"
                                                    ] === "past 5 years"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past 5 years"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="5_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past 5 years"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past 5 years"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 5 year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  value="past 10 years"
                                                  id="10_year"
                                                  checked={
                                                    selectedOption[
                                                      "sale_date"
                                                    ] === "past 10 years"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past 10 years"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="10_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past 10 years"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past 10 years"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 10 year
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="lbl_btn">
                                                <input
                                                  type="radio"
                                                  name="sale_date"
                                                  id="15_year"
                                                  value="past 15 year"
                                                  checked={
                                                    selectedOption[
                                                      "sale_date"
                                                    ] === "past 15 year"
                                                  }
                                                  onChange={(event) =>
                                                    handleRadioChange(
                                                      event,
                                                      "sale_date",
                                                      "past 15 year"
                                                    )
                                                  }
                                                  className="hidden"
                                                />
                                                <label
                                                  for="15_year"
                                                  className="site_btn blank yellow_blank main_color"
                                                  style={{
                                                    backgroundColor:
                                                      selectedOption[
                                                        "sale_date"
                                                      ] === "past 15 year"
                                                        ? "#67773F"
                                                        : "",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        selectedOption[
                                                          "sale_date"
                                                        ] === "past 15 year"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Past 15 year
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk range_slide">
                                            <div className="info_lbl_lbl">
                                              <h6>Sale price</h6>
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="min_sales_price"
                                                  id="min_sales_price"
                                                  className="input"
                                                  placeholder="Min"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "sales"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.min_sales_price
                                                  }
                                                />
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="max_sales_price"
                                                  id="max_sales_price"
                                                  className="input"
                                                  placeholder="Max"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "sales"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.max_sales_price
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col half_flex_form">
                                          <div className="form_blk range_slide">
                                            <div className="info_lbl_lbl">
                                              <h6>Sales price/SF</h6>
                                            </div>

                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="min_sales_price_sf"
                                                  id="min_sales_price_sf"
                                                  className="input"
                                                  placeholder="Min"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "sales"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.min_sales_price_sf
                                                  }
                                                />
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="max_sales_price_sf"
                                                  id="max_sales_price_sf"
                                                  className="input"
                                                  placeholder="Max"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "sales"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.max_sales_price_sf
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Sale price/Acres</h6>
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="min_sales_price_acr"
                                                  id="min_sales_price_acr"
                                                  className="input"
                                                  placeholder="Min"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "sales"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.min_sales_price_acr
                                                  }
                                                />
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <input
                                                  type="text"
                                                  name="max_sales_price_acr"
                                                  id="max_sales_price_acr"
                                                  className="input"
                                                  placeholder="Max"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      event,
                                                      "sales"
                                                    )
                                                  }
                                                  defaultValue={
                                                    searchQueryValues?.max_sales_price_acr
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="footer_filter_btn">
                                        <div className="text_left">
                                          <p>
                                            Filters apply to most recent
                                            transaction
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="react-tabs"
                                    data-rttabs="true"
                                    style={{
                                      display:
                                        selectedTab === 4 ? "block" : "none",
                                    }}
                                  >
                                    <div class="react-tabs__tab-panel react-tabs__tab-panel--selected">
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk range_slide">
                                            <div className="info_lbl_lbl">
                                              <h6>Mortgage Amount</h6>
                                            </div>
                                            <MultiRangeSlider
                                              name="mortgage_amount"
                                              defaultMin={
                                                multSelectOptions
                                                  ?.mortgage_amount?.length > 0
                                                  ? multSelectOptions
                                                      ?.mortgage_amount[0]
                                                  : 0
                                              }
                                              defaultMax={
                                                multSelectOptions
                                                  ?.mortgage_amount?.length > 0
                                                  ? isNaN(
                                                      multSelectOptions
                                                        ?.mortgage_amount[1]
                                                    )
                                                    ? 100000000
                                                    : multSelectOptions
                                                        ?.mortgage_amount[1]
                                                  : 100000000
                                              }
                                              min={0}
                                              max={100000000}
                                              onChange={({ min, max }) =>
                                                handleMultiSelectChange(
                                                  "mortgage_amount",
                                                  min,
                                                  max
                                                )
                                              }
                                              is_mortgage_amount={true}
                                            />
                                          </div>
                                        </div>

                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Mortgage origination</h6>
                                            </div>
                                            <div className="boolean_flex">
                                              {[
                                                {
                                                  value: "last_year",
                                                  label: "Last year",
                                                },
                                                {
                                                  value: "1_2_year",
                                                  label: "Last 1-2 years",
                                                },
                                                {
                                                  value: "2_3_year",
                                                  label: "Last 2-3 years",
                                                },
                                                {
                                                  value: "3_5_year",
                                                  label: "Last 3-5 years",
                                                },
                                                {
                                                  value: "5_7_year",
                                                  label: "Last 5-7 years",
                                                },
                                                {
                                                  value: "7_8_year",
                                                  label: "Last 7-8 years",
                                                },
                                                {
                                                  value: "8_10_year",
                                                  label: "Last 8-10 years",
                                                },
                                                {
                                                  value: "10_12_year",
                                                  label: "Last 10-12 years",
                                                },
                                                {
                                                  value: "12_14_year",
                                                  label: "Last 12-14 years",
                                                },
                                                {
                                                  value: "14_16_year",
                                                  label: "Last 14-16 years",
                                                },
                                                {
                                                  value: "16_18_year",
                                                  label: "Last 16-18 years",
                                                },
                                                {
                                                  value: "18_20_year",
                                                  label: "Last 18-20 years",
                                                },
                                              ].map((option) => (
                                                <div
                                                  className="lbl_btn"
                                                  key={option.value}
                                                >
                                                  <input
                                                    type="radio"
                                                    name="mortage_origination"
                                                    value={option.value}
                                                    checked={
                                                      selectedOption[
                                                        "mortage_origination"
                                                      ] === option.value
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "mortage_origination",
                                                        option.value
                                                      )
                                                    }
                                                    id={option.value}
                                                    className="hidden"
                                                  />
                                                  <label
                                                    htmlFor={option.value}
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "mortage_origination"
                                                        ] === option.value
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "mortage_origination"
                                                          ] === option.value
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      {option.label}
                                                    </span>
                                                  </label>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="footer_filter_btn">
                                        <div className="text_left">
                                          <p>
                                            Filters apply to most recent
                                            transaction
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="react-tabs"
                                    data-rttabs="true"
                                    style={{
                                      display:
                                        selectedTab === 5 ? "block" : "none",
                                    }}
                                  >
                                    <div class="react-tabs__tab-panel react-tabs__tab-panel--selected">
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Tax Year</h6>
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <select
                                                  name="tax_min_year"
                                                  id=""
                                                  className="input"
                                                >
                                                  <option value="">Min</option>
                                                  {Array.from(
                                                    { length: 151 },
                                                    (_, index) => (
                                                      <option
                                                        key={1900 + index}
                                                        value={1900 + index}
                                                        selected={
                                                          parseInt(
                                                            searchQueryValues?.tax_min_year
                                                          ) ==
                                                          1900 + index
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        {1900 + index}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <select
                                                  name="tax_max_year"
                                                  id=""
                                                  className="input"
                                                >
                                                  <option value="">Max</option>
                                                  {Array.from(
                                                    { length: 151 },
                                                    (_, index) => (
                                                      <option
                                                        key={1900 + index}
                                                        value={1900 + index}
                                                        selected={
                                                          parseInt(
                                                            searchQueryValues?.tax_max_year
                                                          ) ==
                                                          1900 + index
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        {1900 + index}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="br"></div>
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Tax amount</h6>
                                            </div>
                                            <div className="half_flex">
                                              <div className="half_field">
                                                <input
                                                  type="number"
                                                  name="min_tax_amount"
                                                  className="input"
                                                  placeholder="Min"
                                                  defaultValue={
                                                    searchQueryValues?.min_tax_amount
                                                  }
                                                />
                                              </div>
                                              <span></span>
                                              <div className="half_field">
                                                <input
                                                  type="number"
                                                  name="max_tax_amount"
                                                  className="input"
                                                  placeholder="Max"
                                                  defaultValue={
                                                    searchQueryValues?.max_tax_amount
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="react-tabs"
                                    data-rttabs="true"
                                    style={{
                                      display:
                                        selectedTab === 6 ? "block" : "none",
                                    }}
                                  >
                                    <div class="react-tabs__tab-panel react-tabs__tab-panel--selected">
                                      <div className="mix_half_flex flex owner_flex">
                                        <div className="col half_flex_form">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Labels</h6>
                                              <ReactTooltip
                                                id="user-labels"
                                                place="bottom"
                                                content="Write label name manually or select among your stored labels to find properties which have these labels."
                                              />
                                              <div
                                                className="info_span"
                                                data-tooltip-id="user-labels"
                                              >
                                                <span className="ic_on">
                                                  <img
                                                    src="/images/info-circle.svg"
                                                    alt=""
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            <input
                                              type="text"
                                              className="input"
                                              placeholder="Search for label"
                                              name="labels_text"
                                              id="labels_text"
                                              onChange={handleLabelTextChange}
                                            />
                                            <div className="sm_br_gap"></div>
                                            <div className="form_blk lbl_table_search">
                                              <table>
                                                <thead>
                                                  <tr>
                                                    <th>Labelss</th>
                                                    <th>Includes</th>
                                                    <th>Exclude</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {userLabels.map((label) => (
                                                    <tr>
                                                      <td
                                                        className="lbl_dot blue_dot"
                                                        style={{
                                                          backgroundColor:
                                                            label.label_color,
                                                        }}
                                                      >
                                                        {label.label_name}
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="checkbox"
                                                          onChange={
                                                            handleLabelIncludeChange
                                                          }
                                                          defaultValue={
                                                            label.label_name
                                                          }
                                                          defaultChecked={
                                                            includeLabels?.includes(
                                                              label?.label_name
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="checkbox"
                                                          defaultValue={
                                                            label.label_name
                                                          }
                                                          onChange={
                                                            handleLabelExcludeChange
                                                          }
                                                          defaultChecked={
                                                            excludeLabels?.includes(
                                                              label?.label_name
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="br"></div>
                                            <div className="form_blk">
                                              <div className="info_lbl_lbl">
                                                <h6>Viewed Date</h6>
                                              </div>
                                              <div className="boolean_flex">
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="viewed_date"
                                                    value="past_day"
                                                    checked={
                                                      selectedOption[
                                                        "viewed_date"
                                                      ] === "past_day"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "viewed_date",
                                                        "past_day"
                                                      )
                                                    }
                                                    id="past_day"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="past_day"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "viewed_date"
                                                        ] === "past_day"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "viewed_date"
                                                          ] === "past_day"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Past day
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="viewed_date"
                                                    value="past_7_days"
                                                    checked={
                                                      selectedOption[
                                                        "viewed_date"
                                                      ] === "past_7_days"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "viewed_date",
                                                        "past_7_days"
                                                      )
                                                    }
                                                    id="past_7_days"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="past_7_days"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "viewed_date"
                                                        ] === "past_7_days"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "viewed_date"
                                                          ] === "past_7_days"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Past 7 days
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="viewed_date"
                                                    value="30_days"
                                                    checked={
                                                      selectedOption[
                                                        "viewed_date"
                                                      ] === "30_days"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "viewed_date",
                                                        "30_days"
                                                      )
                                                    }
                                                    id="30_days"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="30_days"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "viewed_date"
                                                        ] === "30_days"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "viewed_date"
                                                          ] === "30_days"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Past 30 days
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="lbl_btn">
                                                  <input
                                                    type="radio"
                                                    name="viewed_date"
                                                    value="3_mnth"
                                                    checked={
                                                      selectedOption[
                                                        "viewed_date"
                                                      ] === "3_mnth"
                                                    }
                                                    onChange={(event) =>
                                                      handleRadioChange(
                                                        event,
                                                        "viewed_date",
                                                        "3_mnth"
                                                      )
                                                    }
                                                    id="3_mnth"
                                                    className="hidden"
                                                  />
                                                  <label
                                                    for="3_mnth"
                                                    className="site_btn blank yellow_blank main_color"
                                                    style={{
                                                      backgroundColor:
                                                        selectedOption[
                                                          "viewed_date"
                                                        ] === "3_mnth"
                                                          ? "#67773F"
                                                          : "",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          selectedOption[
                                                            "viewed_date"
                                                          ] === "3_mnth"
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      Past 3 months
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="br"></div>
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Note text</h6>
                                            </div>
                                            <input
                                              type="text"
                                              placeholder="Text"
                                              className="input"
                                              name="notes_text"
                                              defaultValue={
                                                searchQueryValues?.notes_text
                                              }
                                            />
                                          </div>
                                          <div className="br"></div>
                                          <div className="form_blk">
                                            <div className="info_lbl_lbl">
                                              <h6>Has a note?</h6>
                                            </div>
                                            <div className="lbl_btn">
                                              <input
                                                type="checkbox"
                                                name="note"
                                                id="note"
                                                defaultChecked={
                                                  searchQueryValues?.note
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label htmlFor="note">Yes</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="btn_blk text-right">
                                    <button
                                      className="site_btn blank yellow_blank"
                                      onClick={(event) =>
                                        handleClearForm(event, "more_filters")
                                      }
                                    >
                                      Clear
                                    </button>
                                    <button
                                      type="submit"
                                      onClick={(event) =>
                                        handleSubmit(event, "more_filters")
                                      }
                                      className="site_btn"
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        ref={props.searchRef}
                        href="javascript:void(0)"
                        className="site_btn blank yellow_blank"
                        onClick={(event) => handleSubmit(event, "search_field")}
                      >
                        Search Result
                      </a>
                    </div>
                  </div>
                  <div className="cell_show_filters">
                    <button
                      className="site_btn"
                      onClick={ToggleHideSearch}
                      type="button"
                    >
                      <span>Show Search</span>
                      <img src="/images/eye_circle_hide.svg" alt="" />
                    </button>
                  </div>
                </div>
                <div className="colR hide_cell_filter">
                  {setShowSearchResults && !showMap && (
                    <button
                      className="site_btn blank no_filter yellow_blank"
                      onClick={ToggleHideSearch}
                      type="button"
                    >
                      <span>Hide Search</span>
                      <img src="/images/eye_circle_hide.svg" alt="" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {
            // isLoadingform ? (
            //   <LoadingScreen />
            // ) : (
            showSearchResults && (
              <SearchResultsComponent
                data={searchResultsData}
                handlePageClick={handlePageClick}
                hideSearch={hideSearch}
                ToggleHideSearch={ToggleHideSearch}
                sortValue={sortValue}
                setSortValue={setSortValue}
                handleSubmit={handleSubmit}
                propertyTypeExists={propertyTypeExists}
                searchedPage={page}
                drawnFeatures={props.drawnFeatures}
                setDrawnFeatures={props.setDrawnFeatures}
                Maptoggle={Maptoggle}
                setSearchQueryValues={setSearchQueryValues}
              />
            )
            // )
          }
        </>
      )}
    </>
  );
};

export default FiltersSearch;
