import React, { useEffect,useState } from "react";
import Intro from "./Intro";
import PricingCard from "./Pricing-card";

import { fetchCheckout } from "../../../states/actions/fetchCheckout";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import ImageControl from "../../common/ImageControl";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ToastContainer } from "react-toastify";
import { format_amount } from '../../../helpers/helpers';
import MetaGenerator from "../../common/meta-generator";

const Checkout = ({enterprise=false}) => {

  
  const { plan_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchCheckout.content);
  const isLoading = useSelector((state) => state.fetchCheckout.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  // console.log(data?.plan)
  const { content, plan, terms_conditions, subscription_agreement, meta } = data;
  const site_settings = useSelector(
    (state) => state.fetchSiteSettings.site_settings
  );
  const subscription = site_settings?.member?.mem_subscription;
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    dispatch(fetchCheckout({ plan_id,enterprise:enterprise ? 1 : 0,user_encoded_id:queryParams.get("user_encoded_id") }));
  }, []);

  // useDocumentTitle(data.page_title);
  let stripePromise = ''
  if(site_settings?.member?.mem_email === process.env.REACT_APP_STRIPE_TESTING_USER){
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TESTING_KEY)
  }
  else{
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
  }
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MetaGenerator page_title={data?.page_title} meta_desc={meta} />
          <Elements stripe={stripePromise}>
            <ToastContainer />
            <section className="logon">
              <div className="contain">
                <div className="flex">
                  <Intro subscription_plan_id={parseInt(subscription?.plan_id)} subscription={subscription} terms_conditions={terms_conditions} subscription_agreement={subscription_agreement} enterprise={enterprise} />
                  <div className="colR self_start pricing_check_out flex price_flex new_price_flex">
                    <PricingCard plan={plan} />
                  </div>
                </div>
              </div>
              <div className="lg_image">
                <ImageControl src={content.image1} folder="images" />
              </div>
            </section>
          </Elements>
        </>
      )}
    </>
  );
};

export default Checkout;
