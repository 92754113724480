import http from "../../helpers/http";
import { doObjToFormData } from "../../helpers/helpers";

import {
  FETCH_SITE_SETTINGS,
  FETCH_SITE_SETTINGS_SUCCESS,
  FETCH_SITE_SETTINGS_FAILED,
  UPDATE_USER_CREDITS,
  UPDATE_USER_CONTACT_INFO
} from "./actionTypes";

export const fetchSiteSettings = () => (dispatch) => {
  dispatch({
    type: FETCH_SITE_SETTINGS,
    payload: null
  });
  http
    .post(
      "site-settings",
      doObjToFormData({ token: localStorage.getItem("authToken") })
    )
    .then(({ data }) => {
      if(localStorage.getItem("authToken") && (data?.not_logged || (data?.member===null || data?.member===undefined || data?.member==='' || data?.member===false))){
        
        localStorage.removeItem("authToken");
        window.location.relaod()
      }
      dispatch({
        type: FETCH_SITE_SETTINGS_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      localStorage.clear();
      window.location.reload();
      dispatch({
        type: FETCH_SITE_SETTINGS_FAILED,
        payload: error
      });
    });
};
export const updateMEmberCredits = (credits,property_id=null) => (dispatch) => {
  // console.log(property_id,'property_id')
  // if(property_id){
    http
    .post(
      "get-user-credits",
      doObjToFormData({ token: localStorage.getItem("authToken") })
    )
    .then(({ data }) => {
     dispatch({
      type: UPDATE_USER_CREDITS,
      payload: data?.credits
    });
    })
    .catch((error) => {
      console.log(error)
    });
  // }
  // else{
  //    dispatch({
  //     type: UPDATE_USER_CREDITS,
  //     payload: credits
  //   });
  // }
 
};
export const updateContactInfo = (contact_info) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_CONTACT_INFO,
    payload: contact_info
  });
};
