import React, { useEffect, useState } from "react";
import ExportInfo from "./Export-info";
import { useLocation } from "react-router-dom";

import { writeFile, utils } from "xlsx";
import { unparse } from "papaparse";

import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer, toast } from "react-toastify";
import http from "../../../helpers/http";
import { doObjToFormData } from "../../../helpers/helpers";
import { getExportProperties } from "../../../states/actions/fetchExport";
import ExportToFile from "./export-file";
import Text from "../../common/Text";
import { updateMEmberCredits } from "../../../states/actions/fetchSiteSettings";
const Export = () => {
  const location = useLocation();

  const getParcelsFromQuery = () => {
    const params = new URLSearchParams(location.search);
    const parcels = params.get("parcels");
    return parcels ? parcels.split(",") : [];
  };

  const parcel_ids = getParcelsFromQuery();
  const getParamsAsObject = () => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};

    for (let [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }

    return paramsObject;
  };

  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchExport.content);
  const isLoading = useSelector((state) => state.fetchExport.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchExport.isFormProcessing
  );
  const content = data?.content?.content;
  const total_available_credits = useSelector(
    (state) => state.fetchSiteSettings.total_available_credits
  );
  const properties = data?.properties;
  const searched_properties = data?.searched_properties;
  const total_downloads = data?.total_downloads;
  useEffect(() => {
    const searchParams = getParamsAsObject();
    if (parcel_ids?.length > 0) {
      dispatch(
        getExportProperties({
          parcel_ids: JSON.stringify(parcel_ids),
        })
      );
    } else {
      dispatch(getExportProperties(searchParams));
    }
  }, []);
  const [isExporting, setIsExporting] = useState(false);
  const handleExportPdf = (frmData) => {
    setIsExporting(true);
    frmData = {
      ...frmData,
      parcel_ids: parcel_ids?.length > 0 ? JSON.stringify(parcel_ids) : [],
      searched_properties: searched_properties?.length > 0 ? "yes" : "no",
      property_fields:
        frmData?.property_fields !== ""
          ? JSON.stringify(frmData?.property_fields)
          : "",
      labels: frmData?.labels !== "" ? JSON.stringify(frmData?.labels) : "",
    };
    // console.log(frmData); return;
    // dispatch(postExportPdf(frmData));
    http
      .post(`export-properties-csv`, doObjToFormData(frmData))
      .then(({ data, status }) => {
        setIsExporting(false);

        if (status !== 200) {
          toast.error("An error occurred while exporting the data.");
          return;
        }
        if (data?.status == 1) {
          if (data?.listings?.length > 0) {
            handleExport(frmData?.file_type, frmData?.name, data?.listings);
            dispatch(
              updateMEmberCredits(parseInt(data?.total_available_credits))
            );
            localStorage.setItem("export_data", JSON.stringify(frmData));
          } else if (data?.status == 2) {
            toast.error(data?.msg);
            return;
          } else {
            toast.error("No result found for this search to export!");
            return;
          }
        } else {
          toast.error(<Text string={data?.msg} />);
          return false;
        }
      })
      .catch((error) => {
        console.error("Export error", error);
        setIsExporting(false);
        toast.error("An error occurred while exporting the data.");
      });
  };
  const handleExport = (fileType, filename, data) => {
    if (fileType === "csv") {
      // Convert the data to a CSV string
      const csv = unparse(data);

      // Create a blob with the CSV string
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      console.log(blob);
      // Create a link element
      const link = document.createElement("a");

      // Create a URL for the blob and set it as the href attribute
      const url = URL.createObjectURL(blob);
      link.href = url;

      // Set the download attribute with a filename
      link.setAttribute("download", filename + ".csv");

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download by simulating a click
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    } else if (fileType === "excel") {
      // Create a new workbook
      const workbook = utils.book_new();

      // Convert the data to a worksheet
      const worksheet = utils.json_to_sheet(data);

      // Append the worksheet to the workbook
      utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate and download the Excel file
      writeFile(workbook, filename + ".xlsx");
    }
  };
  useDocumentTitle(data?.page_title);
  if (isLoading) return <LoadingScreen />;
  const searchParamsObj = getParamsAsObject();
  return (
    <>
      {/* <ExportToFile /> */}
      <ToastContainer />
      <ExportInfo
        properties={properties}
        handleExportPdf={handleExportPdf}
        isFormProcessing={isFormProcessing || isExporting}
        labels={data?.labels}
        searched_properties={searched_properties}
        searchParams={searchParamsObj}
        total_downloads={total_downloads}
        total_available_credits={total_available_credits}
      />
    </>
  );
};

export default Export;
