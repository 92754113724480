import React, { useEffect, useState } from "react";
import { abbreviatedAmount } from "../../../helpers/helpers";
import http from "../../../helpers/http";
import MapComponent from "./Map";
import { useLocation } from "react-router-dom";

const SearchMap = (props) => {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState({});
  const getAllUrlParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const allParams = {};
    for (const [key, value] of queryParams.entries()) {
      allParams[key] = value;
    }
    return allParams;
  };
  useEffect(() => {
    const params = getAllUrlParams();

    setQueryParams(params); // Update the state with the query parameters
  }, [location.search]); // Run when the URL changes
  // console.log('PARAMS',queryParams)
  // Check if there are no valid parameters
  const hasNoValidParams =
    Object.keys(queryParams).length === 0 ||
    Object.values(queryParams).every((val) => !val || val === "null");
  const { hideSearch, showMap } = props;
  const [PropertiesCount, setPropertiesCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (PropertiesCount === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [PropertiesCount]);

  useEffect(() => {
    http
      .post("getallPropertiesTotal", "")
      .then(({ data }) => {
        setPropertiesCount(data.count);
      })
      .catch((error) => {
        localStorage.clear();
        window.location.reload();
        console.error(error);
      });
  }, []);

  return (
    showMap && (
      <div className={!hasNoValidParams ? "hide cstm_indx" : "cstm_indx"}>
        <section
          className={
            hideSearch ? "search_map less_top_search_map" : "search_map"
          }
        >
          <div className="contain-fluid fix_cell_result_lbl">
            {isLoading ? (
              "fetching total available properties count..."
            ) : (
              <strong>
                {PropertiesCount.toLocaleString()} (
                {abbreviatedAmount(PropertiesCount)}) properties available
              </strong>
            )}
          </div>
        </section>
        <MapComponent
          setDrawnFeatures={props.setDrawnFeatures}
          drawnFeatures={props.drawnFeatures}
          handleSearchClick={props.handleSearchClick}
          page="search"
        />
      </div>
    )
  );
};

export default SearchMap;
