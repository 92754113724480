import {
  FETCH_SIGN_UP_CONTENT,
  FETCH_SIGN_UP_CONTENT_SUCCESS,
  FETCH_SIGN_UP_CONTENT_FAILED,
  CREATE_ACCOUNT_MESSAGE,
  CREATE_ACCOUNT_MESSAGE_SUCCESS,
  CREATE_ACCOUNT_MESSAGE_FAILED,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  RESEND_OTP_CODE,
  RESEND_OTP_CODE_SUCCESS,
  RESEND_OTP_CODE_FAILED,
  BACK_TO_SIGNUP,
  SWITCH_SIGNUP_STEPS
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  isResendingOtp: false,
  step: 0,
  google_signup:false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SIGN_UP_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {}
      };
    case FETCH_SIGN_UP_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_SIGN_UP_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case CREATE_ACCOUNT_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case CREATE_ACCOUNT_MESSAGE_SUCCESS:
      localStorage.setItem("email", payload.email);
      localStorage.setItem("authToken", payload.authToken);
      return {
        ...state,
        isFormProcessing: false,
        
      };
    case CREATE_ACCOUNT_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case BACK_TO_SIGNUP:
      return {
        ...state
      };
    case SWITCH_SIGNUP_STEPS:
      if (payload == "next") {
        return {
          ...state,
          step: state.step + 1
        };
      } else {
        return {
          ...state,
          step: state.step - 1
        };
      }
    case VERIFY_EMAIL:
      return {
        ...state,
        isFormProcessing: true
      };
    case VERIFY_EMAIL_SUCCESS:
      localStorage.setItem("authToken", payload.authToken);
      localStorage.removeItem("firstFormStep");
      localStorage.removeItem("secondFormStep");
      localStorage.removeItem("email");
      return {
        ...state,
        isFormProcessing: true
      };
    case VERIFY_EMAIL_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case RESEND_OTP_CODE:
      return {
        ...state,
        isResendingOtp: true
      };
    case RESEND_OTP_CODE_SUCCESS:
      return {
        ...state,
        isResendingOtp: false
      };
    case RESEND_OTP_CODE_FAILED:
      return {
        ...state,
        isResendingOtp: false,
        error: payload
      };
    default:
      return state;
  }
}
