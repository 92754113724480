import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SAVED_SEARCHES_CONTENT,
  FETCH_SAVED_SEARCHES_CONTENT_SUCCESS,
  FETCH_SAVED_SEARCHES_CONTENT_FAILED,
  FETCH_SAVED_SEARCHES_HEADER,
  FETCH_SAVED_SEARCHES_HEADER_SUCCESS,
  FETCH_SAVED_SEARCHES_HEADER_FAILED,
  DELETE_SEARCH_HISTORY,
  DELETE_SEARCH_HISTORY_SUCCESS,
  DELETE_SEARCH_HISTORY_FAILED,
  UPDATE_SEARCH_HISTORY,
  UPDATE_SEARCH_HISTORY_SUCCESS,
  UPDATE_SEARCH_HISTORY_FAILED,
} from "./actionTypes";

export const fetchSavedSearches = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_SAVED_SEARCHES_CONTENT,
    payload: null,
  });
  http
    .post("saved-searches-page", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      // console.log(data)
      dispatch({
        type: FETCH_SAVED_SEARCHES_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      localStorage.clear();
      window.location.reload();
      dispatch({
        type: FETCH_SAVED_SEARCHES_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const fetchSavedSearchesHeader = () => (dispatch) => {
  dispatch({
    type: FETCH_SAVED_SEARCHES_HEADER,
    payload: null,
  });
  http
    .get("saved-searches-for-header")
    .then(({ data }) => {
      dispatch({
        type: FETCH_SAVED_SEARCHES_HEADER_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SAVED_SEARCHES_HEADER_FAILED,
        payload: error,
      });
    });
};

export const deleteSearchHistory = (search_id) => (dispatch) => {
  let formData = {
    token: localStorage.getItem("authToken"),
    search_id: search_id,
  };
  dispatch({
    type: DELETE_SEARCH_HISTORY,
    payload: null,
  });
  http
    .post("delete-search-history", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success("Search history cleared successfully.", TOAST_SETTINGS);
        dispatch({
          type: DELETE_SEARCH_HISTORY_SUCCESS,
          payload: data,
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: DELETE_SEARCH_HISTORY_FAILED,
            payload: null,
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: DELETE_SEARCH_HISTORY_FAILED,
        payload: error,
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const updateSearchHistory = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  dispatch({
    type: UPDATE_SEARCH_HISTORY,
    payload: null,
  });
  http
    .post("update-search-history", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(data?.msg, TOAST_SETTINGS);
        dispatch({
          type: UPDATE_SEARCH_HISTORY_SUCCESS,
          payload: data,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        if (data.msg) {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: UPDATE_SEARCH_HISTORY_FAILED,
            payload: null,
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: UPDATE_SEARCH_HISTORY_FAILED,
        payload: error,
      });
      // const { data } = error.response;
      // localStorage.removeItem("authToken");
      // window.location.reload();
    });
};
