import {
  FETCH_CHECKOUT_CONTENT,
  FETCH_CHECKOUT_CONTENT_SUCCESS,
  FETCH_CHECKOUT_CONTENT_FAILED,
  SAVE_SUBSCRIPTION_PAYMENT,
  SAVE_SUBSCRIPTION_PAYMENT_SUCCESS,
  SAVE_SUBSCRIPTION_PAYMENT_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CHECKOUT_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {}
      };
    case FETCH_CHECKOUT_CONTENT_SUCCESS:
      if(payload.authToken){
        localStorage.setItem("authToken", payload.authToken);
      }
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_CHECKOUT_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case SAVE_SUBSCRIPTION_PAYMENT:
      return {
        ...state,
        isFormProcessing: true
      };
    case SAVE_SUBSCRIPTION_PAYMENT_SUCCESS:
      return {
        ...state,
        isFormProcessing: false
      };
    case SAVE_SUBSCRIPTION_PAYMENT_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}
