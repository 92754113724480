import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import http from "../../../helpers/http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import Text from "../../../components/common/Text";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import LabelForm from "../../../states/actions/LabelForm";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

// custom
// import MobileAdvance from "./Mobile-advance";
import Mapcomponent from "./Map";

import { FETCH_WISHLIST_CONTENT_SUCCESS } from "../../../states/actions/actionTypes";
import ResultPropertyBlk from "./Result-property-blk";
import { doObjToFormData } from "../../../helpers/helpers";
import { updateMEmberCredits } from "../../../states/actions/fetchSiteSettings";
import PropertyTableViewBlk from "./property-blk-table-view";
const handleLikeClick = (propertyData, dispatch) => {
  if (!propertyData) {
    return; // or show an error message
  }

  //const { property_id } = propertyData; // or whatever property ID field name you have

  http
    .post("/adduserwishlist", propertyData)
    .then((response) => {
      toast.success(response.data.msg, TOAST_SETTINGS);
      dispatch({
        type: FETCH_WISHLIST_CONTENT_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      toast.error(<Text id="wishlist.error" />, TOAST_SETTINGS);
    });
};

// Hover Property

const ResultProperties = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  // Get the current page from URL parameter
  useEffect(() => {
    // Function to parse URL parameters
    const getUrlParameter = (name) => {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    // Get the current page from URL parameter
    const page = parseInt(getUrlParameter('page'));

    // Update state with the current page
    setPage(page || 1);
  }, [window.location.search]);
  const initialPage = page - 1;
  const { hideSearch, handlePageClick, setSelectedPropertyIds, selectedPropertyIds } = props;
  const [propertyIdforlabel, setPropertyIdforlabel] = useState(null);
  const [hoverParcelId, setHoverParcelId] = useState(null);
  const { data } = props;
  const searchCheckboxes = props?.searchCheckboxes
  const newAddedPropertyLabels = props?.newAddedPropertyLabels
  const [labelshow, labelsetShow] = useState(false);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const contact_info = useSelector(
    (state) => state.fetchSiteSettings.contact_info
  );
  useEffect(() => {
    if (contact_info && contact_info.id) {
      // Update filteredProperties with matching email and phone
      const updatedProperties = filteredProperties.map((property) => {
        if (property.id === contact_info.id) {
          return {
            ...property,
            email: contact_info.email,
            phone: contact_info.phone,
          };
        }
        return property;
      });

      setFilteredProperties(updatedProperties);
    }
  }, [contact_info, filteredProperties]);
  // console.log("filteredProperties",filteredProperties)
  const [labelNames, setLabelNames] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  
  const resultView = useSelector((state) => state.resultView.resultView);
  
  // Handler function for label form submission
  const handleLabelFormSubmit = (
    propertyId,
    updatedLabelNames,
    label_color = null
  ) => {
    // Update the label names state with the new data
    const labelObj = { label_name: updatedLabelNames, label_color: label_color };
    setLabelNames((prevLabelNames) => ({
      ...prevLabelNames,
      [propertyId]: labelObj,
    }));
  };

  useEffect(() => {
    setPageCount(Math.ceil(data.totalRecords / 50));
  }, [data.totalRecords]);
  useEffect(() => {
    if (newAddedPropertyLabels) {
      setLabelNames(prevLabels => ({
        ...prevLabels,
        ...newAddedPropertyLabels
      }));
    }
  }, [newAddedPropertyLabels]);
  useEffect(() => {
  }, [labelNames]);


  useEffect(() => {
    if (data?.properties) {
      const initialProperties = data?.properties?.map(property => ({
        ...property,
        checked: false
      }));
      setFilteredProperties(initialProperties);
    }
  }, [data]);
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setFilteredProperties(filteredProperties?.map(property => ({
      ...property,
      checked
    })));
    if (checked) {
      setSelectedPropertyIds(filteredProperties.map(property => property?.parcel_id));
    } else {
      setSelectedPropertyIds([]);
    }
  };

  const handleCheckboxChange = (e, index) => {
    const checked = e.target.checked;
    const updatedProperties = filteredProperties?.map((owner, i) =>
      i === index ? { ...owner, checked } : owner
    );
    setFilteredProperties(updatedProperties);

    if (checked) {
      setSelectedPropertyIds([...selectedPropertyIds, filteredProperties[index]?.parcel_id]);
    } else {
      setSelectedPropertyIds(selectedPropertyIds.filter(id => id !== filteredProperties[index]?.parcel_id));
      setSelectAll(false);
    }
    if (updatedProperties.every(owner => owner.checked)) {
      setSelectAll(true);
    }
  };
  // useEffect(() => {
  //   renderPropertymapdata();
  // }, [resultView]);

  const handleToggleForLabel = (id) => {
    setPropertyIdforlabel(id);

    labelsetShow(!labelshow);
  };
  const renderPropertymapdata = () => {
    if (!filteredProperties || filteredProperties.length === 0) {
      return; // You can display a loading message if there is no data yet
    } else {
      let filterParcelIds = filteredProperties.map((props) => props.parcel_id);
      return (
        <div className="colR">
          <div className="outer_map">
            <div className="map">
              <Mapcomponent
                filterParcelIds={filterParcelIds}
                hoverParcelId={hoverParcelId}
                page="results"
                drawnFeatures={props.drawnFeatures}
                setDrawnFeatures={props.setDrawnFeatures}
                setListingData={props.setListingData}
                setShowowners={props.setShowowners}
                Maptoggle={props.Maptoggle}
                setSearchQueryValues={props.setSearchQueryValues}
                filteredProperties={filteredProperties}
                setFilteredProperties={setFilteredProperties}
                handleSubmit={props.handleSubmit}
                propertyTypeExists={props.propertyTypeExists}
              />
              {/* <iframe src="https://rhinoreconsearchfilterbranch.000webhostapp.com/" width="100%" frameBorder="0" allowFullScreen></iframe> */}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderPropertyData = () => {
    if (!filteredProperties || filteredProperties?.length === 0) {
      // return <p> Drawn feature too small, please try again</p>;
      // return <div style={{width: "100%", height: "40px"}}><p>Result Not Found</p></div> // You can display a loading message if there is no data yet
    } else {
      return (
        <React.Fragment>
          <div className="outer_properties" id="outer_properties">
          
            <strong className="showing_records_label">
              {
                searchCheckboxes ?
                <input
                    type="checkbox"
                    name="select_all"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                  :
                  ""
              }
            
              {data.totalRecords == 0
                ? "0 property."
                : "Showing " +
                ((page - 1) * 50 + 1).toLocaleString() +
                " to " +
                ((page - 1) * 50 + data.properties.length).toLocaleString() +
                " of " +
                data.totalRecords.toLocaleString() +
                (data.totalRecords > 1 ? " properties" : " property")}
            </strong>
            {filteredProperties.map((val, i) => {
              const propertyLabelNames = labelNames;
              return (
                <ResultPropertyBlk
                  val={val}
                  handleHoverProperty={handleHoverProperty}
                  propertyLabelNames={propertyLabelNames}
                  i={i}
                  handleToggleForLabel={handleToggleForLabel}
                  searchCheckboxes={searchCheckboxes}
                  handleCheckboxChange={handleCheckboxChange}
                />
              );
            })}
          </div>
          <div style={{ position: "sticky", bottom: 0 }}>
            <ReactPaginate
              forcePage={initialPage}
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              pageCount={pageCount}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={false}
            />
          </div>
        </React.Fragment>
      );
    }
  };

  useEffect(() => {
    const sections = document.querySelectorAll(
      ".search_result_page.filter_show_after"
    );

    if (sections.length > 1) {
      for (let i = 1; i < sections.length; i++) {
        sections[i].style.display = "none";
      }
    }
  }, []);

  function handleHoverProperty(id) {
    setHoverParcelId(id);
  }

  useEffect(() => {
  }, [resultView]);
  const [OpenEditColumnsPopup, setOpenEditColumnsPopup] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    // "date": "Sales Date",
    // "price": "Sales Price",
    "build": "Year built",
    // "square": "square",
    "property_type": "Property Types",
    "owner": "Name",
    "contact": "Contact Info",
    // "zonedcodelocal": "Zoning",
    // "mortgage1amount": "Mortgage Amount",
    // "taxyearassessed": "Tax Year",
    // "taxassessedvaluetotal": "Tax Price",
    
    
  });
  useEffect(() => {
    if (Object.keys(checkedItems).length > 0) {
      const selectedColumns = Object.keys(checkedItems).filter((key) => checkedItems[key]);
      setDisplayedColumns(selectedColumns);
    }

  }, []);


  const handleEditCheckboxChange = (event) => {
    const { name, checked, id } = event.target;
    setCheckedItems((prev) => ({
      ...prev,
      [id]: checked ? name : undefined,
    }));
  };

  const checkedLabels = Object.keys(checkedItems).filter(key => checkedItems[key]);
  const handleRemoveItem = (id) => {
    setCheckedItems((prev) => {
      const updatedItems = { ...prev };
      delete updatedItems[id];
      return updatedItems;
    });

    // Uncheck the corresponding checkbox
    const checkbox = document.getElementById(id);
    if (checkbox) {
      checkbox.checked = false;
    }
  };
  const [displayedColumns, setDisplayedColumns] = useState([]);
  const handleUpdateColumns = () => {
    const selectedColumns = Object.keys(checkedItems).filter((key) => checkedItems[key]);
    setDisplayedColumns(selectedColumns);
    setOpenEditColumnsPopup(false)
  };
  const [isTypeLoading, setIsTypeLoading] = useState(false);

  
  return (
    <>
      <ToastContainer />
      <section
        className={
          hideSearch
            ? "search_result_page"
            : "search_result_page filter_show_after"
        }
      >
        {resultView == "table" ? (
          <>
            <div className="flex listing_relative">
              <div className="colL">{renderPropertyData()}</div>
              {renderPropertymapdata()}
            </div>
          </>
        ) : (
          <div
            className={
              hideSearch
                ? "owner_filter_sec owner_filter_tbl"
                : "filter_show_after owner_filter_sec owner_filter_tbl"
            }
          >
            <div className="contain-fluid big_contain_fluid">
              <div className="outer_table">
                <div className="highlight_div">
                  <input
                    type="checkbox"
                    name="select_all"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                  <h5>
                    {data.totalRecords == 0
                      ? "0 property."
                      : "Showing " +
                      ((page - 1) * 50 + 1).toLocaleString() +
                      " to " +
                      (
                        (page - 1) * 50 +
                        data.properties.length
                      ).toLocaleString() +
                      " of " +
                      data.totalRecords.toLocaleString() +
                      (data.totalRecords > 1 ? " properties" : " property")}
                  </h5>
                  <div className="customize_columns">
                    <button
                      type="button"
                      className="site_btn" onClick={() => setOpenEditColumnsPopup(true)}
                    >
                      <img src="/images/pencil.svg" alt="" /> Edit Columns
                    </button>
                  </div>
                </div>
                {filteredProperties.map((val, i) => (
                  <PropertyTableViewBlk val={val} handleCheckboxChange={handleCheckboxChange} displayedColumns={displayedColumns} handleToggleForLabel={handleToggleForLabel} handleLikeClick={handleLikeClick} i={i} />
                ))}
              </div>
              <div style={{ position: "sticky", bottom: 0 }}>
                <ReactPaginate
                  forcePage={initialPage}
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={4}
                  pageCount={pageCount}
                  previousLabel="<"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={false}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      {labelshow ? (
        <>
          <div>
            <LabelForm
              onCancel={handleToggleForLabel}
              propertyIdforlabel={propertyIdforlabel}
              onLabelFormSubmit={handleLabelFormSubmit}
            />
          </div>
        </>
      ) : null}
      {
        OpenEditColumnsPopup ?
          <div className="table_popup">
            <div className="table_side_popup">
              <h5>Edit Table Columns</h5>
              <div className="x_btn" onClick={() => setOpenEditColumnsPopup(false)}></div>
              <hr />
              {/* <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p> */}
              <div className="flex">
                <div className="col">
                  <h6>Add/Remove Fields</h6>
                  <div className="check_inner">
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="property_type"
                        name="Property Types"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['property_type'] !== undefined}
                      />
                      <label htmlFor="property_type">Property Types</label>
                    </div>
                  </div>
                  <p><strong>Building & Lot</strong></p>
                  <div className="check_inner">
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="build"
                        name="Year built"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['build'] !== undefined}
                      />
                      <label htmlFor="build">Year built</label>
                    </div>
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="arealotsf"
                        name="Lot Size"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['arealotsf'] !== undefined}
                      />
                      <label htmlFor="arealotsf">Lot Size</label>
                    </div>
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="zonedcodelocal"
                        name="Zoning"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['zonedcodelocal'] !== undefined}
                      />
                      <label htmlFor="zonedcodelocal">Zoning</label>
                    </div>
                  </div>
                  <p><strong>Sales</strong></p>
                  <div className="check_inner">
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="date"
                        name="Sales Date"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['date'] !== undefined}
                      />
                      <label htmlFor="date">Sales Date</label>
                    </div>
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="price"
                        name="Sales Price"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['price'] !== undefined}
                      />
                      <label htmlFor="price">Sales Price</label>
                    </div>
                  </div>
                  <p><strong>Debt</strong></p>
                  <div className="check_inner">
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="mortgage1amount"
                        name="Mortgage Amount"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['mortgage1amount'] !== undefined}
                      />
                      <label htmlFor="mortgage1amount">Mortgage Amount</label>
                    </div>
                  </div>
                  {/* <p><strong>Distressed</strong></p>
                  <div className="check_inner">
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="foreclosureauctionsale"
                        name="Foreclosed"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['foreclosureauctionsale'] !== undefined}
                      />
                      <label htmlFor="foreclosureauctionsale">Foreclosed</label>
                    </div>
                  </div> */}
                  <p><strong>Tax</strong></p>
                  <div className="check_inner">
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="taxyearassessed"
                        name="Tax year"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['taxyearassessed'] !== undefined}
                      />
                      <label htmlFor="taxyearassessed">Tax year</label>
                    </div>
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="taxassessedvaluetotal"
                        name="Tax Amount"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['taxassessedvaluetotal'] !== undefined}
                      />
                      <label htmlFor="taxassessedvaluetotal">Tax Amount</label>
                    </div>
                  </div>
                  <p><strong>Building Area</strong></p>
                  <div className="check_inner">
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="square"
                        name="Square"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['square'] !== undefined}
                      />
                      <label htmlFor="square">Building Area</label>
                    </div>
                  </div>
                  <p><strong>Reported Owner</strong></p>
                  <div className="check_inner">
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="owner"
                        name="Name"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['owner'] !== undefined}
                      />
                      <label htmlFor="owner">Owner Name</label>
                    </div>
                  </div>
                  <p><strong>Owner Contact Info</strong></p>
                  <div className="check_inner">
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="contact"
                        name="contact"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['contact'] !== undefined}
                      />
                      <label htmlFor="contact">Contact Info</label>
                    </div>
                    {/* <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="email"
                        name="Email"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['email'] !== undefined}
                      />
                      <label htmlFor="email">Email</label>
                    </div>
                    <div className="lbl_btn">
                      <input
                        type="checkbox"
                        id="call"
                        name="Phone"
                        onChange={handleEditCheckboxChange}
                        defaultChecked={checkedItems['call'] !== undefined}
                      />
                      <label htmlFor="call">Phone</label>
                    </div> */}
                  </div>
                </div>
                <div className="col">
                  <h6>Selected Fields and Orders</h6>
                  <ul>
                    {checkedLabels.map((key) => (
                      <li key={key}>
                        <span>{checkedItems[key]}</span>
                        <div className="x_btn" onClick={() => handleRemoveItem(key)}></div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="br"></div>
              <div className="btn_blk">
                <button className="site_btn" type="button" onClick={handleUpdateColumns}>Update</button>
              </div>
            </div>
          </div>
          :
          ""
      }
    </>
  );
};

export default ResultProperties;
