import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { swithSignupSteps } from "../../../states/actions/fetchSignup";
import { useDispatch } from "react-redux";
import SubmitButton from "../../common/SubmitButton";
import ShowPasswordIcon from "../../common/ShowPasswordIcon";
import GoogleLogin from "react-google-login";
import PhoneInput from "react-phone-number-input";

const Personal = ({ content }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState(
    JSON.parse(localStorage.getItem("firstFormStep"))
  );
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const watchAllFields = watch();
  const onSuccess = (response) => {
    setValue("fname", response !== null ? response?.profileObj?.givenName : "");
    setValue(
      "lname",
      response !== null ? response?.profileObj?.familyName : ""
    );

    setValue("email", response !== null ? response?.profileObj?.email : "");
    setValue("googleId", response !== null ? response?.googleId : "");
    setValue(
      "mem_image",
      response !== null ? response?.profileObj?.imageUrl : ""
    );
    let formData = { ...response, type: "google" };
    localStorage.setItem("firstFormStep", JSON.stringify(formData));
    // dispatch(swithSignupSteps("next"));
  };
  const onFailure = (response) => {
    console.log("FAILED", response);
    // if (response?.error) {
    //     toast.error("Login Error >> " + response?.error + " >> " + response?.details,
    //         TOAST_SETTINGS
    //     );
    // }
  };
  const firstSubmit = (data, e) => {
    e.preventDefault();
    // console.log(data);return;
    localStorage.setItem("firstFormStep", JSON.stringify(data));
    dispatch(swithSignupSteps("next"));
  };
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState("");
  const handlePassword = (passwordValue) => {
    console.log(passwordValue);
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length == 5
        ? "Strong"
        : verifiedList.length >= 2
        ? "Medium"
        : "Weak";

    setProgress(`${(verifiedList.length / 5) * 100}%`);
    setMessage(strength);

    // console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
  };
  const getActiveColor = (type) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "#FF0054";
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, times: [2, 3, 2] }}
        exit={{ opacity: 0 }}
      >
        <fieldset>
          <div className="step_from">Step 1 of 2</div>
          <div className="option_lbl">
            <ul>
              <li className="active">Personal Info</li>
              <li>Company</li>
            </ul>
          </div>
          <h4>{content.left_sec_heading_1}</h4>
          <p>{content.left_sec_text_1}</p>
          <form onSubmit={handleSubmit(firstSubmit)}>
            <div className="blk_form_flex">
              <div className="blk_form">
                <h6>First Name</h6>
                <div className="form_blk">
                  <input
                    defaultValue={defaultFormValues?.fname}
                    type="text"
                    className="input"
                    placeholder="Please enter your First name"
                    {...register("fname", {
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                        message: "Invalid value",
                      },
                      required: "First Name is required.",
                      minLength: {
                        value: 2,
                        message:
                          "First Name should contains at-least 2 letters.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="fname"
                    render={({ message }) => (
                      <p className="validation_error"> {message}</p>
                    )}
                  />
                </div>
              </div>
              <div className="blk_form">
                <h6>Last Name</h6>
                <div className="form_blk">
                  <input
                    defaultValue={defaultFormValues?.lname}
                    type="text"
                    className="input"
                    placeholder="Please enter your Last name"
                    {...register("lname", {
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                        message: "Invalid value",
                      },
                      required: "Last Name is required.",
                      minLength: {
                        value: 2,
                        message:
                          "Last Name should contains at-least 2 letters.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="lname"
                    render={({ message }) => (
                      <p className="validation_error"> {message}</p>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="blk_form">
              <h6>Email</h6>
              <div className="form_blk">
                <input
                  defaultValue={defaultFormValues?.email}
                  type="text"
                  className="input"
                  placeholder="Please enter your email"
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <p className="validation_error"> {message}</p>
                  )}
                />
              </div>
            </div>
            <div className="blk_form">
              <h6>Phone Number</h6>
              <div className="form_blk">
                <PhoneInput
                  defaultCountry="US"
                  className="input"
                  {...register("mem_phone", {
                    required: "Phone is required",
                  })}
                  value={defaultFormValues?.mem_phone}
                />
                <ErrorMessage
                  errors={errors}
                  name="mem_phone"
                  render={({ message }) => (
                    <p className="validation_error"> {message}</p>
                  )}
                  className="input"
                />
              </div>
            </div>
            <div className="blk_form">
              <div className="flex pass_flex">
                <h6>Password</h6>
                {watchAllFields?.password !== undefined &&
                watchAllFields?.password !== null &&
                watchAllFields?.password !== "" &&
                watchAllFields?.password.length !== 0 ? (
                  <p
                    className="message"
                    style={{ color: getActiveColor(message) }}
                  >
                    {message}
                  </p>
                ) : null}
              </div>

              <div className="form_blk pass_blk">
                <input
                  defaultValue={defaultFormValues?.password}
                  type={showPassword ? "text" : "password"}
                  className="input"
                  placeholder="Enter password"
                  {...register("password", {
                    required: "Password is required.",
                    pattern: {
                      value:
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{8,}$/,
                      message:
                        "The password should contain at least 8 characters, that contain at least one lowercase letter, one uppercase letter and one numeric digit.",
                    },
                  })}
                  onChange={({ target }) => {
                    handlePassword(target.value);
                  }}
                />

                <ShowPasswordIcon
                  showPassword={showPassword}
                  handleShowPassword={() => setShowPassword(!showPassword)}
                />
                <div className="progress-bg">
                  <div
                    className="progress"
                    style={{
                      width: progress,
                      backgroundColor: getActiveColor(message),
                    }}
                  ></div>
                </div>
                {progress !== "100%" ? (
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => (
                      <p className="validation_error"> {message}</p>
                    )}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="blk_form">
              <h6>Confirm Password</h6>
              <div className="form_blk pass_blk">
                <input
                  defaultValue={defaultFormValues?.confirm_password}
                  type={showPassword2 ? "text" : "password"}
                  className="input"
                  placeholder="Enter password again"
                  {...register("confirm_password", {
                    required: "Confirm Password is required.",
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match.";
                      }
                    },
                    minLength: {
                      value: 6,
                      message: "Password should be atleast 6 characters long.",
                    },
                  })}
                />
                <ShowPasswordIcon
                  showPassword={showPassword2}
                  handleShowPassword={() => setShowPassword2(!showPassword2)}
                />
                <ErrorMessage
                  errors={errors}
                  name="confirm_password"
                  render={({ message }) => (
                    <p className="validation_error"> {message}</p>
                  )}
                />
              </div>
            </div>
            <div className="small_txt">
              <small>
                Must be at least 12 characters and combination of small and
                capital letters, symbols, and numbers.
              </small>
            </div>

            <div className="btn_blk">
              <SubmitButton
                classes="site_btn nextBtn"
                button_text={content.left_sec_button_text_1}
              />

              {/* <button className="site_btn blank" type="button">
                <img src="/images/google.svg" alt="" /> Sign up with Google
              </button> */}
              <div className="google_wrap">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Sign up with Google"
                  className="gmBtn"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  // disabled={(isGoogleProcessing === true) ? 'disabled' : ''}
                  cookiePolicy={"single_host_origin"}
                />
              </div>
            </div>
          </form>
          <div className="dont_acc">
            <p>
              {content.signin_link_left_text}{" "}
              <Link to="/login">{content.signin_link_text}</Link>
            </p>
          </div>
        </fieldset>
      </motion.div>
    </>
  );
};

export default Personal;
