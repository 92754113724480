import React, { useState, useEffect, useRef } from "react";
import http from "../../../helpers/http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { doObjToFormData } from "../../../helpers/helpers";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import { useForm } from "react-hook-form";
import { toggleResultView } from "../../../states/actions/resultView";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SaveSearchPopup from "./saveSearchPopup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { updateMEmberCredits } from "../../../states/actions/fetchSiteSettings";
import DeleteLabel from "./deleteLabel";

const ResultFilters = (props) => {
  const dispatch = useDispatch();
  const [showNewLabelInput, setShowNewLabelInput] = useState(false);
  const [addLabelToProperties, setAddLabelToProperties] = useState(false);
  const [editLabel, setEditLAbel] = useState({ show: false, item: null });
  const [labelName, setLabelName] = useState("");
  const [propertyLabel, setPropertyLabel] = useState("");
  const [labelColor, setLabelColor] = useState("#000000");
  const { ToggleHideSearch, hideSearch, ownertoggle, showowners } = props;
  const sortValue = props?.sortValue;
  const selectedPropertyTitles = props?.selectedPropertyTitles;
  const selectedPropertyParcelIDs = props?.selectedPropertyParcelIDs;
  const searchCheckboxes = props?.searchCheckboxes;
  const setSearchCheckboxes = props?.setSearchCheckboxes;
  const selectedPropertyIds = props?.selectedPropertyIds;
  const newAddedPropertyLabels = props?.newAddedPropertyLabels;
  const setNewAddedPropertyLabels = props?.setNewAddedPropertyLabels;
  const setSortValue = props?.setSortValue;
  const [report, setReport] = useState(false);
  const [sort, setSort] = useState(false);
  const [label, setLabel] = useState(false);
  const [labels, setLabels] = useState([]);
  const [userLabels, setUserLabels] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const resultView = useSelector((state) => state.resultView.resultView);
  const handleChangeEditLabel = (e, item) => {
    if (e.target.name === "label_name") {
      setLabelName(e.target.value);
    }
    if (e.target.name === "label_color") {
      setLabelColor(e.target.value);
    }
  };
  const handleChangePropertyLabel = (e, item) => {
    setPropertyLabel(e.target.value);
  };
  const handleCloseEditLabel = (e) => {
    e.preventDefault();
    setLabelName("");
    setLabelColor("");
    setEditLAbel({ show: false, item: null });
  };
  const handleShowEditLabel = (e, item) => {
    e.preventDefault();
    setLabelName(item?.label_name);
    setLabelColor(item?.label_color);
    setEditLAbel({ show: true, item: item });
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const popupRef2 = useRef();
  const ToggleReport = () => {
    setSort(false);
    setLabel(false);
    setReport(!report);
  };
  const ToggleLabel = () => {
    setSort(false);
    setReport(false);
    setLabel(!label);
  };
  const ToggleOwners = () => {
    var owners = true;
    ownertoggle(owners);
    const sections = document.querySelectorAll(
      ".search_result_page.filter_show_after"
    );

    if (sections.length > 1) {
      for (let i = 0; i < sections.length; i++) {
        sections[i].style.display = "none";
      }
    }
  };
  const ToggleProperties = () => {
    ownertoggle(false);
    const sections = document.querySelectorAll(
      ".search_result_page.filter_show_after"
    );

    if (sections.length > 1) {
      for (let i = 1; i < sections.length; i++) {
        sections[i].style.display = "none";
      }
    }

    // show the first section
    if (sections.length > 0) {
      sections[0].style.display = "block";
    }
  };
  const ToggleSort = () => {
    setLabel(false);
    setReport(false);
    setSort(!sort);
  };
  const selectSortValue = (e, value) => {
    e.preventDefault();
    setSortValue(value);
    setLabel(false);
    setReport(false);
    setSort(!sort);
    props.handleSearchSortSubmit(e, value);
  };
  const showLabelInput = () => {
    setShowNewLabelInput(!showNewLabelInput);
  };

  const handleLabelNameChange = (e) => {
    setLabelName(e.target.value);
  };

  const handleLabelColorChange = (e) => {
    setLabelColor(e.target.value);
  };

  const postLabel = () => {
    // Perform API post request with labelName and labelColor
    // ...

    if (labelName !== "" && labelColor !== "") {
      const myFormData = {
        labelName: labelName,
        labelColor: labelColor,
      };

      http
        .post("create-user-label-properties", myFormData)
        .then(({ data }) => {
          toast.success(data.msg);
          if (data.status == 1) {
            setLabelName("");
            setLabelColor("");
            setLabels([...labels, { name: labelName, color: labelColor }]);
          }
        })
        .catch((error) => {});
    } else {
      toast.error("Please select at least one label property.");
    }

    // Reset input fields
  };
  const updateLabel = (e, label_id) => {
    // Perform API post request with labelName and labelColor
    // ...
    e.preventDefault();
    if (label_id) {
      if (labelName !== "" && labelColor !== "") {
        const myFormData = {
          labelName: labelName,
          labelColor: labelColor,
        };

        http
          .post("update-user-label-properties/" + label_id, myFormData)
          .then(({ data }) => {
            toast.success(data.msg);
            if (data.status == 1) {
              setUserLabels(
                userLabels?.map((label) => {
                  if (label.id === label_id) {
                    return {
                      ...label,
                      label_name: labelName,
                      label_color: labelColor,
                    };
                  }
                  return label;
                })
              );
              setEditLAbel({ show: false, item: null });
              setLabelName("");
              setLabelColor("");
            }
          })
          .catch((error) => {});
      } else {
        toast.error("Please select at least one label property.");
      }
    } else {
      toast.error("Invalid label");
    }

    // Reset input fields
  };
  const addLabelsToProperties = (e) => {
    e.preventDefault();
    setNewAddedPropertyLabels({});
    if (selectedPropertyIds?.length > 0) {
      if (
        propertyLabel !== null &&
        propertyLabel !== undefined &&
        propertyLabel !== ""
      ) {
        const label = userLabels.find(
          (label) => label.id === parseInt(propertyLabel)
        );

        const myFormData = {
          label_id: propertyLabel,
          selected_properties: JSON.stringify(selectedPropertyIds),
        };

        http
          .post("add-label-selected-properties", doObjToFormData(myFormData))
          .then(({ data }) => {
            if (data.status == 1) {
              let property_labels_arr = data?.property_labels_arr;
              toast.success(data.msg);

              setNewAddedPropertyLabels((prevLabelNames) => ({
                ...prevLabelNames,
                ...property_labels_arr,
              }));
              setAddLabelToProperties(false);
              setLabel(false);
              setTimeout(() => {
                // window.location.reload()
              }, 3000);
            } else {
              toast.error(data.msg);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        toast.error("please select label to continue!");
      }
    } else {
      toast.error("please select a property first to continue!");
    }

    // Reset input fields
  };
  useEffect(() => {
    fetchUserLabels();
  }, []);
  const handleOutsideClick2 = (e) => {
    if (!popupRef2.current.contains(e.target)) {
      setLabel(false);
      setReport(false);
      setSort(false);
    }
  };

  const handleToggleResultView = (type) => {
    dispatch(toggleResultView(type));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick2);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick2);
    };
  }, []);

  const fetchUserLabels = () => {
    http
      .post("get-user-label-properties", "")
      .then(({ data }) => {
        setUserLabels(data.labels);
      })
      .catch((error) => {});
  };
  const renderLabels = () => {
    return labels.map((label, index) => (
      <div
        key={label.id + 12789100000000}
        className="label_btn"
        style={{ backgroundColor: label.color }}
      >
        <span>{label.name}</span>
      </div>
    ));
  };
  const location = useLocation();
  const navigate = useNavigate();
  const handleExportList = () => {
    if (
      selectedPropertyIds?.length > 0 &&
      selectedPropertyParcelIDs?.length > 0
    ) {
      let parcel_ids = selectedPropertyParcelIDs?.join(",");
      navigate(`/export?parcels=${encodeURIComponent(parcel_ids)}`);
    } else {
      if (location.search) {
        navigate(`/export${location.search}`);
      } else {
        toast.error("please select properties to continue");
      }
    }
    // let filters = JSON.parse(localStorage.getItem("currentFilters"));
    // setIsExporting(true);
    // filters = { ...filters, selected_properties: JSON.stringify(selectedPropertyIds) }

    // http.post(`export-filtered-results`, doObjToFormData(filters), {
    //   responseType: "blob",
    // })
    //   .then(({ data, status }) => {

    //     setIsExporting(false);

    //     if (status !== 200) {
    //       toast.error("An error occurred while exporting the data.");
    //       return;
    //     }

    //     // If the response is HTML, it is likely an error message
    //     const contentType = data.type || "";
    //     // if (contentType.includes("text/html")) {
    //     //   toast.error("You have reached your downloads limit.");
    //     //   return;
    //     // }

    //     const href = URL.createObjectURL(data);
    //     const link = document.createElement("a");
    //     link.href = href;
    //     let filename = "filtered-properties.csv";
    //     link.setAttribute("download", filename);
    //     document.body.appendChild(link);
    //     link.click();

    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(href);
    //   })
    //   .catch((error) => {
    //     setIsExporting(false);
    //     toast.error("An error occurred while exporting the data.");
    //   });
  };
  const total_available_credits = useSelector(
    (state) => state.fetchSiteSettings.total_available_credits
  );
  const handleGenerateReport = (data, e) => {
    e.preventDefault();
    let filters = JSON.parse(localStorage.getItem("currentFilters"));
    filters = { ...filters, ...data };
    setIsGeneratingReport(true);
    let number_of_properties = parseInt(data?.number_of_properties);
    http
      .post(`filtered-properties-report-pdf`, doObjToFormData(filters), {
        responseType: "blob",
      })
      .then(({ data }) => {
        setIsGeneratingReport(false);
        if (data.type == "text/html") {
          toast.error("You have reached your downloads limit.");
          return false;
        }
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        let filename = "properties report list.pdf";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        dispatch(
          updateMEmberCredits(
            parseInt(total_available_credits) - number_of_properties
          )
        );
      })
      .catch((error) => {
        setIsGeneratingReport(false);
      });
  };

  const labelDrop = useRef();
  const reportDrop = useRef();
  const reportDrop2 = useRef();
  const sortByDrop = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        !labelDrop?.current?.contains(e.target) &&
        !reportDrop?.current?.contains(e.target) &&
        !reportDrop2?.current?.contains(e.target) &&
        !sortByDrop?.current?.contains(e.target)
      ) {
        setLabel(false);
        setReport(false);
        setSort(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);
  const truncateText = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };
  const [saveSearchPopup, setSaveSearchPopup] = useState(false);

  return (
    <>
      <div
        ref={popupRef2}
        className={hideSearch ? "result_filter less_top" : "result_filter"}
      >
        <div className="contain-fluid big_contain_fluid">
          <div className="filter_result_flex">
            <div className="_col">
              <div className="filter_blk">
                <button
                  disabled={showowners}
                  className={
                    showowners ? "site_btn" : "site_btn blank yellow_blank"
                  }
                  onClick={ToggleProperties}
                >
                  Properties
                </button>
                <div class="drop_filter drop_result_btn owner_filter_tbl">
                  <button
                    disabled={!showowners}
                    className={
                      showowners ? "site_btn blank yellow_blank" : "site_btn"
                    }
                    onClick={ToggleOwners}
                  >
                    Owners
                  </button>
                </div>
                <div class="drop_filter drop_result_btn hide_main_filter_new_result_big">
                  <button
                    class="filter_btn site_btn blank yellow_blank no_filter"
                    onClick={ToggleLabel}
                  >
                    <span>Label</span>
                    <img src="/images/label_circle.svg" alt="" />
                  </button>
                  <div
                    className={
                      label ? "drop_cnt medium active" : "drop_cnt medium"
                    }
                  >
                    <div className="head_label_flex">
                      <span>Label properties</span>
                      <input type="text" defaultValue={"1"} />
                      <span>to</span>
                      <input type="text" defaultValue={"2"} />
                    </div>
                    <div className="flex_label_new_new">
                      <div className="col_left_lbl">
                        <div className="label_btn red_lbl">
                          <span>good one</span>
                          <span>(1)</span>
                        </div>
                        <div className="label_btn black_lbl">
                          <span>Unblocked Owners</span>
                          <span>(5)</span>
                        </div>
                      </div>
                      <div className="col_right_lbl">
                        <button>
                          <img src="/images/pencil.svg" alt="" />
                        </button>
                      </div>
                    </div>
                    <div className="new_lbl_plus_sign">
                      <button onClick={showLabelInput}>New label +</button>
                      {showNewLabelInput && (
                        <div>
                          <input
                            type="text"
                            name="label_name"
                            placeholder="Label Name"
                            value={labelName}
                            onChange={handleLabelNameChange}
                          />
                          <input
                            type="color"
                            name="label_color"
                            value={labelColor}
                            onChange={handleLabelColorChange}
                          />
                          <button onClick={postLabel}>Save</button>
                        </div>
                      )}
                    </div>
                    <button className="new_label_properties_new">
                      Label Properties
                    </button>
                  </div>
                </div>
                <div
                  class="drop_filter drop_result_btn hide_main_filter_new_result_big"
                  ref={reportDrop2}
                >
                  <button
                    class="filter_btn site_btn blank yellow_blank no_filter"
                    onClick={ToggleReport}
                  >
                    <span>Report</span>
                    <img src="/images/report_circle.svg" alt="" />
                  </button>
                  <div
                    className={
                      report ? "drop_cnt medium active" : "drop_cnt medium"
                    }
                  >
                    <div className="header_text text-center">
                      <h5>Print properties</h5>
                    </div>
                    <form action="">
                      <div className="form_blk">
                        <h6>Report title</h6>
                        <input
                          type="text"
                          className="input"
                          placeholder="Title"
                        />
                      </div>
                      <div className="form_blk">
                        <h6>Number Of Properties</h6>
                        <input
                          type="text"
                          className="input"
                          placeholder="Title"
                        />
                      </div>
                      {/* <div className="form_blk">
                        <div className="inner text-center">
                          <div className="carbe_icon">
                            <img src="/images/carbon_upload.svg" alt="" />
                          </div>
                          <h6>Choose logo</h6>
                        </div>
                      </div> */}
                      <button className="site_btn" type="button">
                        Create report
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="_col">
              <div className="filter_blk">
                <div
                  class="drop_filter drop_result_btn hide_main_filter_new_result_small"
                  ref={labelDrop}
                >
                  <button
                    class="filter_btn site_btn blank yellow_blank no_filter"
                    onClick={ToggleLabel}
                  >
                    <span>Label</span>
                    <img src="/images/label_circle.svg" alt="" />
                  </button>
                  <div
                    className={
                      label ? "drop_cnt medium active" : "drop_cnt medium"
                    }
                  >
                    {/* <div className="add_label_selected_props flex">
                      {
                        selectedPropertyIds?.length > 0 ?
                          <button className="site_btn" type="button" onClick={() => setAddLabelToProperties(true)}>Add Label to selected properties</button>
                          :
                          ""
                      }

                    </div> */}
                    <div className="head_label_flex">
                      <span>Label properties</span>
                      <input type="text" defaultValue={"1"} />
                      <span>to</span>
                      <input type="text" defaultValue={"2"} />
                    </div>
                    <div className="flex_label_new_new">
                      <div className="col_left_lbl">
                        {userLabels.map((label) => (
                          <div className="_col_left">
                            <div
                              key={label.id + 78912789100000000}
                              className={`label_btn ${label.label_color}`}
                              style={{ backgroundColor: label.label_color }}
                            >
                              <span>{label.label_name}</span>
                              {/* <span>(2)</span> */}
                            </div>
                            <div className="col_right_lbl">
                              <button
                                type="button"
                                onClick={(e) => handleShowEditLabel(e, label)}
                              >
                                <img src="/images/pencil.svg" alt="" />
                              </button>
                            </div>
                          </div>
                        ))}
                        {renderLabels()}
                      </div>
                    </div>
                    {addLabelToProperties === true ? (
                      <div className="new_label_new_new edit_label">
                        <div
                          className="crosBtn"
                          onClick={() => setAddLabelToProperties(false)}
                        >
                          x
                        </div>
                        <h5>Selected Properties are:</h5>
                        <div className="selected_properties">
                          {" "}
                          {selectedPropertyIds?.length > 0 ? (
                            <>
                              {selectedPropertyTitles?.map(
                                (selectedPropertyTitle, index) => {
                                  return <span>{selectedPropertyTitle}</span>;
                                }
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="flex custom_choose_lbl">
                          <label>Select Label:</label>
                          <select
                            name=""
                            className="input"
                            onChange={handleChangePropertyLabel}
                          >
                            {userLabels.map((label) => (
                              <option
                                value={label?.id}
                                selected={
                                  propertyLabel === label?.id ? true : false
                                }
                              >
                                {label?.label_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <button
                          onClick={addLabelsToProperties}
                          className="site_btn"
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {editLabel?.show === true && editLabel?.item !== null ? (
                      <div className="new_label_new_new edit_label">
                        <div className="crosBtn" onClick={handleCloseEditLabel}>
                          x
                        </div>
                        <div className="flex">
                          <input
                            type="text"
                            name="label_name"
                            placeholder="Label Name"
                            value={labelName}
                            className="input"
                            onChange={handleChangeEditLabel}
                          />

                          <input
                            type="color"
                            name="label_color"
                            value={labelColor}
                            className="new_clr_choose"
                            onChange={handleChangeEditLabel}
                          />
                        </div>
                        <div className="btnBlk flex">
                          <DeleteLabel
                            label_id={editLabel?.item?.id}
                            setUserLabels={setUserLabels}
                            setEditLAbel={setEditLAbel}
                          />
                          <button
                            onClick={(e) => updateLabel(e, editLabel?.item?.id)}
                            className="site_btn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="new_lbl_plus_sign">
                      <button onClick={showLabelInput}>New label +</button>
                      {showNewLabelInput && (
                        <div className="new_label_new_new">
                          <div className="flex">
                            <input
                              type="text"
                              name="label_name"
                              placeholder="Label Name"
                              value={labelName}
                              className="input"
                              onChange={handleLabelNameChange}
                            />

                            <input
                              type="color"
                              name="label_color"
                              value={labelColor}
                              className="new_clr_choose"
                              onChange={handleLabelColorChange}
                            />
                          </div>
                          <button onClick={postLabel} className="site_btn">
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                    {/* <button
                      className="new_label_properties_new"
                      onClick={ToggleLabel}
                    >
                      Label Properties
                    </button> */}
                    {editLabel?.show !== true &&
                    editLabel?.item === null &&
                    addLabelToProperties !== true ? (
                      <div className="add_label_selected_props flex">
                        {selectedPropertyIds?.length > 0 ? (
                          <button
                            className="site_btn"
                            type="button"
                            onClick={() => setAddLabelToProperties(true)}
                          >
                            Add Label to selected properties
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  class="drop_filter drop_result_btn hide_main_filter_new_result_small"
                  ref={reportDrop}
                >
                  <button
                    class="filter_btn site_btn blank yellow_blank no_filter"
                    onClick={ToggleReport}
                  >
                    <span>Report</span>
                    <img src="/images/report_circle.svg" alt="" />
                  </button>
                  <div
                    className={
                      report ? "drop_cnt medium active" : "drop_cnt medium"
                    }
                  >
                    <div className="header_text text-center">
                      <h5>Print properties</h5>
                    </div>
                    <form
                      method="POST"
                      onSubmit={handleSubmit(handleGenerateReport)}
                    >
                      <div className="form_blk">
                        <h6>Report title</h6>
                        <input
                          type="text"
                          className="input"
                          placeholder="Title"
                          {...register("title", {
                            required: "Please enter the title of the report",
                          })}
                        />
                        <span className="validation-error">
                          {errors.title?.message}
                        </span>
                      </div>
                      <div className="form_blk">
                        <h6>Number of properties</h6>
                        <span className="validation-error reportValidationError">
                          {errors.number_of_properties?.message}
                        </span>
                        <input
                          defaultValue={5}
                          type="number"
                          className="input"
                          placeholder="Enter number of properties"
                          min="0"
                          max="50"
                          {...register("number_of_properties", {
                            required: "Please enter number of properties",
                            min: {
                              value: 1,
                              message: "Value must be at least 1",
                            },
                            max: {
                              value: 50,
                              message: "Value must not exceed 50",
                            },
                          })}
                        />
                      </div>

                      {/* <div className="form_blk">
                        <div className="inner text-center">
                          <div className="carbe_icon">
                            <img src="/images/carbon_upload.svg" alt="" />
                          </div>
                          <h6>Choose logo</h6>
                        </div>
                      </div> */}
                      <button
                        className="site_btn"
                        type="submit"
                        disabled={isGeneratingReport}
                      >
                        <FormProcessingSpinner
                          isFormProcessing={isGeneratingReport}
                        />
                        Create report
                      </button>
                    </form>
                  </div>
                </div>
                <button
                  className="site_btn yellow_blank blank"
                  onClick={handleExportList}
                  disabled={isExporting}
                >
                  <FormProcessingSpinner isFormProcessing={isExporting} />
                  Export List
                </button>
                <div
                  class="drop_filter drop_result_btn cell_top_high_mrgn"
                  ref={sortByDrop}
                >
                  <button
                    class="filter_btn site_btn blank yellow_blank no_filter"
                    onClick={ToggleSort}
                  >
                    <span>
                      Sort by:{" "}
                      {sortValue === "year"
                        ? "Year Built"
                        : sortValue === "lot_area"
                        ? "Lot Area"
                        : sortValue === "sale_price"
                        ? "Sale Price"
                        : sortValue === "property_type"
                        ? "Property Type"
                        : sortValue === "last_sale_date"
                        ? "Last Sale Date"
                        : sortValue === "tax_year"
                        ? "Tax Year"
                        : sortValue === "tax_amount"
                        ? "Tax Amount"
                        : sortValue === "property_sf"
                        ? "Property square footage"
                        : sortValue === "street_name"
                        ? "Street Name"
                        : ""}
                    </span>
                    <img src="/images/filter_circle.svg" alt="" />
                  </button>
                  <div
                    className={
                      sort
                        ? "drop_cnt height_cnt_drop_bdy exact_wide active"
                        : "drop_cnt height_cnt_drop_bdy exact_wide"
                    }
                  >
                    <div className="option_lst">
                      <button
                        onClick={(e) => selectSortValue(e, "property_type")}
                        className={sortValue == "property_type" ? "active" : ""}
                      >
                        Property Type
                      </button>
                      <button
                        onClick={(e) => selectSortValue(e, "lot_area")}
                        className={sortValue == "lot_area" ? "active" : ""}
                      >
                        Lot Area
                      </button>
                      <button
                        onClick={(e) => selectSortValue(e, "last_sale_date")}
                        className={
                          sortValue == "last_sale_date" ? "active" : ""
                        }
                      >
                        Last Sale Date
                      </button>
                      <button
                        onClick={(e) => selectSortValue(e, "sale_price")}
                        className={sortValue == "sale_price" ? "active" : ""}
                      >
                        Sales Price
                      </button>
                      <button
                        onClick={(e) => selectSortValue(e, "year")}
                        className={sortValue == "year" ? "active" : ""}
                      >
                        Year Built
                      </button>
                      <button
                        onClick={(e) => selectSortValue(e, "tax_year")}
                        className={sortValue == "tax_year" ? "active" : ""}
                      >
                        Tax Year
                      </button>
                      <button
                        onClick={(e) => selectSortValue(e, "tax_amount")}
                        className={sortValue == "tax_amount" ? "active" : ""}
                      >
                        Tax Amount
                      </button>
                      <button
                        onClick={(e) => selectSortValue(e, "property_sf")}
                        className={sortValue == "property_sf" ? "active" : ""}
                      >
                        Property square footage
                      </button>
                      <button
                        onClick={(e) => selectSortValue(e, "street_name")}
                        className={sortValue == "street_name" ? "active" : ""}
                      >
                        Street Name
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  class={
                    hideSearch
                      ? "filter_btn site_btn view_searches_btn"
                      : "after_filter_view filter_btn site_btn view_searches_btn"
                  }
                  onClick={ToggleHideSearch}
                >
                  <span>View Search</span>
                  <img src="/images/show_search_icon.svg" alt="" />
                </button>
                <button
                  className={`togg_icon_search cell_show_map ${
                    resultView == "list" && "active"
                  }`}
                  onClick={() => handleToggleResultView("list")}
                  disabled={resultView == "list"}
                  data-tooltip-id="table-view"
                >
                  <img src="/images/list-props.svg" alt="" />
                </button>
                <ReactTooltip
                  id="table-view"
                  place="bottom"
                  content="Table View"
                />
                <button
                  className={`togg_icon_search cell_show_map ${
                    resultView == "table" && "active"
                  }`}
                  onClick={() => handleToggleResultView("table")}
                  disabled={resultView == "table"}
                  data-tooltip-id="map-view"
                >
                  <img src="/images/map-props.svg" alt="" />
                </button>
                <ReactTooltip id="map-view" place="bottom" content="Map View" />
                <a
                  className="site_btn blank yellow_blank"
                  href="/search-property"
                >
                  Reset Search
                </a>
                <button
                  className={
                    searchCheckboxes
                      ? "site_btn"
                      : "filter_btn site_btn blank yellow_blank"
                  }
                  type="button"
                  onClick={() => setSearchCheckboxes(!searchCheckboxes)}
                >
                  Select Properties
                </button>
                <button
                  type="button"
                  className="filter_btn site_btn blank yellow_blank"
                  onClick={() => setSaveSearchPopup(true)}
                >
                  Save Search
                </button>
              </div>
            </div>
            {showowners && (
              <div className="_col">
                <div className="filter_blk">
                  <button
                    type="button"
                    className={`togg_icon_search ${
                      resultView == "list" && "active"
                    }`}
                    onClick={() => handleToggleResultView("list")}
                    disabled={resultView == "list"}
                    data-tooltip-id="table-view"
                  >
                    <img src="/images/list-props.svg" alt="" />
                  </button>
                  <button
                    type="button"
                    className={`togg_icon_search ${
                      resultView == "table" && "active"
                    }`}
                    onClick={() => handleToggleResultView("table")}
                    disabled={resultView == "table"}
                    data-tooltip-id="map-view"
                  >
                    <img src="/images/map-props.svg" alt="" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {saveSearchPopup ? (
        <SaveSearchPopup setSaveSearchPopup={setSaveSearchPopup} />
      ) : (
        ""
      )}
    </>
  );
};

export default ResultFilters;
