import parse from "html-react-parser";
import moment from "moment";
import * as paths from "../constants/paths";
export function getYearOptions() {
  const options = [];
  for (let year = 1950; year <= 2024; year++) {
    options.push({ value: year, label: year });
  }
  return options;
}
export function getNumbersRange(from = 100, until = 100000) {
  const options = [];
  for (let num = from; num <= until; num++) {
    options.push({ value: num, label: num });
  }
  return options;
}
export function doObjToFormData(obj) {
  var formData = new FormData();
  // const { geometry, ...obj } = objValues;
  const geometry = obj?.geometry;
  // console.log(obj);
  for (var key in obj) {
    if (Array.isArray(obj[key])) {
      for (let [keyv, value] of Object.entries(obj[key])) {
        formData.append(key + "[]", JSON.stringify(value));
      }
    } else {
      if (typeof obj[key] == "object") {
        formData.append(key, JSON.stringify(obj[key]));
      } else {
        formData.append(key, obj[key]);
      }
    }
  }

  if (!!geometry && geometry !== null && geometry !== undefined) {
    formData.append("geometry", geometry);
  }
  return formData;
}

export function checkPattern(string, pattern) {
  var re = /^[a-zA-Z0-9_]+$/;

  return pattern.test(string);
}
export function format_amount(x) {
  if (x !== undefined) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "$" + parts.join(".");
  } else {
    return "$0.00";
  }
}
export function doObjToFormDataWithoutString(obj) {
  var formData = new FormData();
  for (var key in obj) {
    if (Array.isArray(obj[key])) {
      for (let [keyv, value] of Object.entries(obj[key])) {
        formData.append(key + "[]", value);
      }
    } else {
      if (typeof obj[key] == "object") {
        formData.append(key, obj[key]);
      } else {
        formData.append(key, obj[key]);
      }
    }
  }
  return formData;
}

export function doFirstUpperRestLower(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export function doParseHTML(string) {
  return parse(string);
}

export function getBackgroundImageUrl(src) {
  const base_api_url = paths.API_CMS_BG_IMAGES_URL;
  return base_api_url + src;
}
export const convertToValidVideoLink = (link) => {
  const youtubeMatch = link.match(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/);
  const vimeoMatch = link.match(/^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+/);

  if (youtubeMatch) {
    // Ensure YouTube link starts with https://
    return link.replace(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)/, 'https://www.youtube.com');
  } else if (vimeoMatch) {
    // Ensure Vimeo link starts with https://
    return link.replace(/^(https?:\/\/)?(www\.)?(vimeo\.com)/, 'https://vimeo.com');
  }

  // For other links, return as is
  return link;
};
export function getBackgroundImageUrlThumb(src, thumb = 1) {
  const base_api_url = paths.API_CMS_BG_IMAGES_URL;
  if (thumb > 1) return base_api_url + thumb + "p_" + src;
  else return base_api_url + "thumb_" + src;
}

export function getUploadsUrl(folder, src) {
  const base_api_url = paths.API_UPLOADS_URL;
  return base_api_url + folder + "/" + src;
}

export function eventDateFormat(date) {
  return moment(date).format("DD, MMMM YYYY");
}
export function format_date(date) {
  return moment(date).format("MMMM D, YYYY");
}

export function eventTimeFormat(time) {
  return moment(time, "HHmm").format("hh:mm A");
}

export function onlyDayThreeletters(date) {
  return moment(date).format("ddd");
}

export function onlyDateTwoletters(date) {
  return moment(date).format("DD");
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function nowPlus6Days() {
  let days = [];
  let daysRequired = 7;

  for (let i = 0; i <= daysRequired; i++) {
    days.push(moment().add(i, "days").format("YYYY-MM-DD"));
  }
  return days;
}

export function jobProgressColor(value) {
  switch (value) {
    case "waiting_on_hold":
    case "completed":
      return "_amber";
    case "received":
    case "accepted":
    case "passed":
    case "not_applicable":
      return "_green";
    case "failed":
    case "withdrawn":
    case "not_invited":
      return "_red";
    default:
      return "";
  }
}

export function getActiveClassname(value) {
  switch (value) {
    case 0:
      return "first_active";
    case 1:
      return "second_active";
    case 2:
      return "third_active";
    case 3:
      return "four_active";
    case 4:
      return "five_active";
    default:
      return "";
  }
}
export function getInvitationStatus(value) {
  switch (value) {
    case 0:
      return "Pending";
    case 1:
      return "Completed";
    case 2:
      return "Rejected";
    default:
      return "n/a";
  }
}
export function getSearchTag(type, data) {
  switch (type) {
    case "search":
      // Constructing string to display all keys with values
      const keysWithValues = Object.entries(data)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
      return `Search: ${keysWithValues}`;
    case "property_type":
      return `Property type: ${data}`;
    default:
      return "n/a";
  }
}


export function timeAgo(date) {
  return moment(date).fromNow();
}

export function getWordInitials(word) {
  const bits = word.trim().split(" ");
  return bits
    .map((bit) => bit.charAt(0))
    .join("")
    .toUpperCase();
}
export const checkoutTrialDate = () => {
  const currentDate = moment();

  // Add 7 days to the current date
  const newDate = currentDate.add(2, 'days');

  // Manually adjust to Eastern Standard Time (EST) offset
  // EST is UTC-5, so we subtract 5 hours
  const estOffset = -5;
  const newDateInEST = newDate.utcOffset(estOffset).format('MM/DD/YYYY');

  return newDateInEST;
};
export function convertToEmbedUrl(youtubeUrl) {
  const videoId = youtubeUrl.split("v=")[1]?.split("&")[0] || youtubeUrl.split('/').pop();
  return `https://www.youtube.com/embed/${videoId}`;
}
export function short_text(text, length = 25) {
  if (text.length > length) {
    let str = text.substring(0, length);
    return str + "...";
  } else {
    return text;
  }
}
export function logout() {
  localStorage.removeItem("authToken");
  window.location.href = "/login";
}

export function price_format(price, currency = "$") {
  return `${currency} ${price}`;
}

export function abbreviatedAmount(value) {
  var suffixes = ["", "K", "M", "B", "T"];
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
}

export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  // Sort the arrays to compare them
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}
export const isFutureDate = (dateString) => {
  return moment(dateString).isAfter(moment());
};
